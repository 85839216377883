import React, { useEffect, useRef, useState } from "react";
import enums from "helpers/enums";
import {
  copyToClipboard,
  formatDateToRead,
  isDivisionControlled,
  isVendor,
  selectedDivisionId,
  uniqueArray,
  userDetails,
} from "@ui/Utils/helper";
import { paginateWithHook } from "../../api";
import { useNavigate } from "react-router-dom/dist/index";
import MatTable from "@ui/components/MatTable";
import AdvanceSearchFilter from "@ui/Controls/Search/AdvanceSearchFilter";
import Header from "@ui/ComponentUtils/Header";
import { Tabs, Tab, Box } from "@mui/material";
import GeneralListPage from "@ui/ComponentUtils/GeneralListPage";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import EditLocationRoundedIcon from "@mui/icons-material/EditLocationRounded";
import { Grid, Tooltip } from "@material-ui/core/index";
import { globalDateTimeFormat } from "@ui/ReactHookFormControls/index";
import commonConfig from "config/commonConfig";

const columns = [
  {
    title: "Order ID",
    field: "orderId",
  },
  {
    title: "Products",
    field: "items",
    sorting: false,
    render: (row) =>
      row?.items?.filter((item) =>
        isDivisionControlled ? item?.division?._id === selectedDivisionId : true
      )?.length,
  },
  {
    title: "Quantity",
    sorting: false,
    render: (row) =>
      row?.items
        ?.filter((item) =>
          isDivisionControlled
            ? item?.division?._id === selectedDivisionId
            : true
        )
        ?.map((item) => item?.quantity)
        .reduce((a, b) => a + b, 0),
  },
  {
    title: "Order Status",
    sorting: false,
    render: (row) => {
      const types = uniqueArray(
        (row.items || []).map((item) => item?.orderStatus)
      );

      return types.join(", ");
    },
  },
  {
    title: "Created",
    hidden: isVendor,
    render: (row) => (
      <div style={{ minWidth: "110px" }}>
        <div>{formatDateToRead(row?.dateCreated)}</div>
        <div style={{ fontSize: "12px" }}>
          {row?.createdBy?.firstName + " " + row?.createdBy?.lastName}
        </div>
        {row.createdBy?.companyDetails?.name ? (
          <div style={{ fontSize: "12px", fontStyle: "italic" }}>
            {row.createdBy?.companyDetails?.name}
          </div>
        ) : null}

        {/* <div style={{ fontSize: "12px", fontStyle: "italic" }}>
          {row?.createdBy?.role}
        </div> */}
      </div>
    ),
  },
];

const sessionStorageConstants = {
  tab: enums.models["e-creatives"].linkItOrders + "/tab",
};

const LinkItOrdersList = () => {
  const [selectedTab, setSelectedTab] = useState(0); // State to track the selected tab

  const model = enums.models["e-creatives"]?.linkItOrders,
    searchFields = [
      "orderId",
      "items.product.sku",
      "items.product.styleNo",
      "createdBy.phone",
      "createdBy.companyDetails.name",
      "items.orderStatus",
    ],
    userFields = ["$createdBy.firstName", "$createdBy.lastName"],
    headerTitle = "LINK it",
    title = "",
    hideAction = { create: true, delete: true, selection: true },
    onRowClick = true;

  const [listPageTableQuery, setListPageTableQuery] = useState({
    pageSize: 10,
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalCalc, setTotalCalc] = useState({ grossWeight: 0, netWeight: 0 });
  const navigate = useNavigate();
  const tableRef = useRef();
  const [filterCriteria, setFilterCriteria] = useState([]);
  const sessionTab = window.sessionStorage.getItem(sessionStorageConstants.tab);

  useEffect(() => {
    if (sessionTab && (sessionTab !== "undefined" || sessionTab !== "null")) {
      setSelectedTab(JSON.parse(sessionTab));
    }
  }, []);

  const linksColumns = [
    {
      title: "Access",
      render: (row) => (
        <div>
          {(row?.validUsers || [])
            .map(
              (user) =>
                `${user?.firstName + " " + (user?.lastName || "")}: ☎️ ${
                  user.phone || ""
                }`
              // ` `
            )
            .join(", ")}
        </div>
      ),
    },
    {
      title: "Catalogues",
      field: "catalogues",
      render: (row) => {
        const isExpired = new Date(row.expirationDate) < new Date();
        return (
          <div style={{ color: isExpired ? "red" : "green" }}>
            {(row.catalogues || []).map((cat) => cat.name).join(", ")}
          </div>
        );
      },
    },
    {
      title: "Expiry Date",
      field: "expirationDate",
      render: (row) => {
        const isExpired = new Date(row.expirationDate) < new Date();
        return (
          <div style={{ color: isExpired ? "red" : "green" }}>
            {formatDateToRead(row.expirationDate, globalDateTimeFormat.read)}
          </div>
        );
      },
    },

    {
      title: "Created",
      render: (row) => (
        <div style={{ minWidth: "110px" }}>
          <div>{formatDateToRead(row?.dateCreated)}</div>
          <div style={{ fontSize: "12px" }}>
            {row?.createdBy?.firstName + " " + row?.createdBy?.lastName}
          </div>

          <div style={{ fontSize: "12px", fontStyle: "italic" }}>
            {row?.createdBy?.role}
          </div>
        </div>
      ),
    },
    {
      title: "Link",
      render: (row) => {
        return (
          <div>
            <pre
              style={{
                // fontSize: "8px",
                // maxWidth: "150px",
                // whiteSpace: "pre-wrap",
                // wordBreak: "break-all",
                fontStyle: "italic",
              }}
            >
              {row.shortCode}
            </pre>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MyHoverIcons
                  muiIcon={
                    <FileCopyOutlinedIcon style={{ fontSize: "42px" }} />
                  }
                  muiIconHover={<FileCopyIcon style={{ fontSize: "42px" }} />}
                  onClick={() => {
                    copyToClipboard(row.url);
                  }}
                  tooltip={"Copy Expiry LINK"}
                />
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Revoke">
                  <MyHoverIcons
                    muiIcon={
                      <EditLocationOutlinedIcon style={{ fontSize: "42px" }} />
                    }
                    muiIconHover={
                      <EditLocationRoundedIcon style={{ fontSize: "42px" }} />
                    }
                    tooltip={"Edit"}
                    onClick={() => {
                      navigate(
                        `/${enums.models["e-creatives"].expiryLinks}/${row.code}`,
                        {
                          state: { formData: row },
                        }
                      );
                    }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        );
      },
    },
  ];

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
    window.sessionStorage.setItem(sessionStorageConstants.tab, newValue);
  };

  const getData = async (query = {}) => {
    setListPageTableQuery(query);
    let tabCondition = {};

    if (isDivisionControlled) {
      tabCondition["items.division._id"] = selectedDivisionId;
    }

    if (isVendor) {
      tabCondition["items.vendorCode"] = { $in: userDetails.vendorCode };
      tabCondition["items.orderStatus"] = {
        $in: [
          enums["vendor-portal"].orderStatus.accepted,
          enums["vendor-portal"].orderStatus.assigned,
          enums["vendor-portal"].orderStatus.canceled,
          enums["vendor-portal"].orderStatus.delivered,
          enums["vendor-portal"].orderStatus.inProgress,
          enums["vendor-portal"].orderStatus.resent,
        ],
      };
    }

    if (
      query.criteria?.[query.criteria.length - 1]?.searchElements?.length ||
      filterCriteria?.[filterCriteria.length - 1]?.searchElements?.length
    ) {
      if (query.criteria?.[0]?.$or) {
        query.criteria.unshift(tabCondition);
      } else {
        query.criteria = query.criteria || [];
        query.criteria[0] = tabCondition;
      }
    } else {
      query.criteria = [tabCondition, {}];
    }

    //backend sorting
    if (query.orderBy) {
      query.sort = {
        [query.orderBy.field]: query.orderDirection,
      };
    }
    query = {
      ...query,
    };

    try {
      let response = await paginateWithHook(model, query);

      if (response && response.paginationInfo) {
        const records = response.records || [];

        setTotalCalc({
          grossWeight: response.paginationInfo.totalGrossWeight || 0,
          netWeight: response.paginationInfo.totalNetWeight || 0,
        });
        return {
          data: records || [],
          page: response.paginationInfo.currentPageNo - 1 || 0,
          totalCount: response.paginationInfo.totalRecords || 0,
        };
      }
    } catch (error) {
      console.log(error);

      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    }
  };

  const onSelectionChange = (rows) => {
    let currentPageNum = tableRef.current.state.query.page;
    if (selectedRows && selectedRows.length + 1 < currentPageNum) {
      for (let index = selectedRows.length; index < currentPageNum; index++) {
        selectedRows[index] = [];
      }
    }
    selectedRows && selectedRows.splice(currentPageNum, 1, rows);
    setSelectedRows(rows);
  };

  const handleSearch = (filterQuery = []) => {
    const queryData = {
      pageSize: listPageTableQuery.pageSize,
      page: 0,
      criteria: filterQuery,
    };
    setFilterCriteria(filterQuery);
    tableRef.current.onQueryChange(queryData);
  };

  return (
    <div>
      <Header links={[]} pageTitle={headerTitle} actions={[]} />

      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="Tabs for Orders and LINKs"
      >
        <Tab label="Orders" />
        {isVendor ? null : <Tab label="LINKs" />}
      </Tabs>

      <Box mt={2}>
        {selectedTab === 0 && (
          <>
            <AdvanceSearchFilter
              searchFields={searchFields}
              userFields={userFields}
              handleSearch={handleSearch}
            />

            <MatTable
              tableRef={tableRef}
              columns={columns}
              data={(query) => getData(query)}
              title={`
        Gross: ${totalCalc.grossWeight?.toFixed(3)} 
        <<< -- >>>
        ${totalCalc.netWeight?.toFixed(3)} :Net`}
              options={{
                selection: !hideAction.selection,
                search: false,
                pageSize: listPageTableQuery.pageSize,
              }}
              onRowClick={(_, selectedRow) => {
                onRowClick && navigate(`/${model}/${selectedRow.code}`);
              }}
              onSelectionChange={onSelectionChange}
            />
          </>
        )}
        {selectedTab === 1 && (
          <GeneralListPage
            customBaseApi={commonConfig.baseApi["e-creatives"]}
            columns={linksColumns}
            // title="LINKs"
            model={enums.models["e-creatives"].expiryLinks}
            // headerTitle="LINKs"
            searchFields={[
              "url",
              "catalogues.name",
              "validUsers.firstName",
              "validUsers.lastName",
              "validUsers.phone",
            ]}
            dateFields={["expirationDate"]} //  "dateCreated" let's only search expiration date only for now which is the usecase..
            hideAction={{ delete: true, create: true }}
            options={{ selection: false }}
            onRowClick={false}
            withHook={true}
          />
        )}
      </Box>
    </div>
  );
};

export default LinkItOrdersList;
