import React, { useState, useEffect } from "react";
import MicIcon from "@mui/icons-material/Mic";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import StopIcon from "@mui/icons-material/Stop";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";

const VoiceSearch = ({ onVoiceSearch }) => {
  const [isListening, setIsListening] = useState(false);
  //   const [transcript, setTranscript] = useState("");
  const [recognition, setRecognition] = useState(null);

  useEffect(() => {
    // Initialize SpeechRecognition
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recognitionInstance = new SpeechRecognition();
      recognitionInstance.continuous = false;
      recognitionInstance.interimResults = false;
      recognitionInstance.lang = "en-US";

      recognitionInstance.onresult = (event) => {
        const speechToText = event.results[0][0].transcript;
        // setTranscript(speechToText);
        if (onVoiceSearch) onVoiceSearch(speechToText);
      };

      recognitionInstance.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
      };

      recognitionInstance.onend = () => {
        setIsListening(false);
      };

      setRecognition(recognitionInstance);
    } else {
      console.warn("SpeechRecognition is not supported in this browser.");
    }
  }, [onVoiceSearch]);

  const handleStartListening = () => {
    if (recognition) {
      setIsListening(true);
      recognition.start();
    } else {
      alert("Your browser does not support voice recognition.");
    }
  };

  const handleStopListening = () => {
    if (recognition) {
      setIsListening(false);
      recognition.stop();
    }
  };

  return (
    <div onClick={isListening ? handleStopListening : handleStartListening}>
      {isListening ? (
        <MyHoverIcons
          muiIcon={<StopCircleOutlinedIcon />}
          muiIconHover={<StopIcon />}
          tooltip={"stop it 🙉"}
        />
      ) : (
        <MyHoverIcons
          muiIcon={<MicNoneOutlinedIcon />}
          muiIconHover={<MicIcon />}
          tooltip={"speak it 🙊"}
        />
      )}
    </div>
  );
};

export default VoiceSearch;
