import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import MyAsyncAutocomplete from "@ui/MuiComponents/AutoComplete/MyAsyncAutocomplete";
import enums from "helpers/enums";
import { MyAutocomplete } from "@ui/MuiComponents/index";
import { getSingleRecord, search } from "@ui/ComponentUtils/blueprintAPIs";
import moment from "moment";
import { checkDirty, getDistinctZones } from "./api";
import DateRangePickerModal from "@ui/ComponentUtils/DateRange/DateRangePickerModal";
import DocUpload from "../Upload/DocUpload";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import CancelScheduleSendOutlinedIcon from "@mui/icons-material/CancelScheduleSendOutlined";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import "./style.css";
import _ from "lodash";
import {
  formatNumberToRead,
  formatWeight,
  isVendor,
  regexPurify,
  subscription,
  userDetails,
} from "@ui/Utils/helper";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Emitter from "@ui/Utils/CustomEventEmitter";
import Switch from "@mui/material/Switch";
import { makeStyles } from "@mui/styles";
import commonEnums from "@ui/Utils/commonEnums";
const empty = enums.keywords.empty; // there in de/enums.json

const useStyles = makeStyles(() => ({
  stats: {
    padding: "4px 8px",
    textAlign: "center",
  },
  iconButton: {
    padding: "4px 8px",
  },
  statText: {
    fontSize: "1rem",
    fontWeight: "bold",
    fontFamily: "Roboto, sans-serif",
    letterSpacing: 2,
  },
  statTextValue: {
    fontSize: "1rem",
    fontWeight: 500,
    fontFamily: "Roboto, sans-serif",
    letterSpacing: "0.05em",
    color: enums.colorTheme.primary,
  },
}));

const FilterByFields = ({
  searchSessionName,
  handleFieldLevelSearch,
  defaultCondition,
  metaInfo = {},
  initialFiles = [],
  printingStationScreen, // for paginate API and mongoose search criteria to get attached for filterCriteria returned from this.component
  style = {},
  stockWise,
  setStockWise = () => {},
  hideFilterStats,
  loading,
}) => {
  const [condition, setCondition] = useState({});
  const [purities, setPurities] = useState([]);
  const [vendorCodes, setVendorCodes] = useState([]);
  const [zones, setZones] = useState([]);
  const [openPackageDatePicker, setOpenPackageDatePicker] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState();
  const [files, setFiles] = useState(initialFiles);
  const [isOpen, setIsOpened] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (defaultCondition && Object.keys(defaultCondition).length) {
      setCondition(defaultCondition);

      if (defaultCondition.packageDate?.startDate) {
        setSelectedDateRange({
          startDate: new Date(defaultCondition.packageDate.startDate),
          endDate: new Date(defaultCondition.packageDate.endDate),
        });
      }
    }
  }, [defaultCondition]);

  useEffect(() => {
    (async () => {
      try {
        if (isVendor) {
          setVendorCodes(userDetails.vendorCode || []);
        } else {
          setVendorCodes([
            empty,
            ...((
              await getSingleRecord(enums.models["vendor-portal"].vendorCodes)
            )?.availableCodes || []),
          ]);
        }
      } catch (err) {
        console.log(err);
      }
    })();

    (async () => {
      try {
        setPurities((await search(enums.models["data-engine"].purity)) || []);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const thisZones = [];
        const res = await getDistinctZones();

        (res || []).map((zone) =>
          zone?.name?.toString().trim()
            ? thisZones.push(zone?.name)
            : !thisZones.find((zone) => zone === empty)
            ? thisZones.unshift(empty)
            : null
        );
        setZones(thisZones);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const onFilter = () => {
    if (
      !printingStationScreen &&
      condition.imageFastSearch &&
      !condition.inferenceFormData?.name
    ) {
      return Emitter.emit("alert_error", "required: IMAGE ☢️");
    }
    const filterCriteria = printingStationScreen
      ? getMongooseCriteria(condition)
      : getFieldFilters(condition);

    handleFieldLevelSearch(filterCriteria);
    searchSessionName &&
      window.sessionStorage.setItem(
        searchSessionName,
        JSON.stringify(condition)
      );
    if (!printingStationScreen) {
      setIsOpened(false);
    }
  };

  const onReset = () => {
    window.sessionStorage.removeItem(searchSessionName);
    setCondition({});
    setSelectedDateRange();
    handleFieldLevelSearch({});
    setFiles([]);
  };

  const FilterActions = () => {
    const [isHovered, setIsHovered] = React.useState(false);

    return (
      <Grid
        container
        spacing={2}
        lg={4}
        // justify="flex-end"
      >
        <Grid item lg={6} container>
          <Button
            variant="contained"
            onClick={onFilter}
            style={{
              backgroundColor: "gold",
              fontWeight: "bold",
              height: 50,
              color: "white",
              position: "relative",
              overflow: "hidden",
              minWidth: "100%",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <span
              style={{
                position: "relative",
                zIndex: 2,
                fontFamily: "'Roboto Mono', monospace",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              Filter
            </span>
            <div className={`fish ${isHovered ? "fish-hover" : ""}`} />
          </Button>
        </Grid>

        <Grid item lg={1} container justify="center">
          {/* Reset Button */}
          <MyHoverIcons
            muiIcon={
              <CancelScheduleSendOutlinedIcon
                style={{
                  fontSize: "48px",
                }}
              />
            }
            muiIconHover={
              <CancelScheduleSendIcon
                style={{
                  fontSize: "48px",
                }}
              />
            }
            tooltip={"Reset"}
            onClick={onReset}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <div
      style={{
        ...style,
        backgroundColor: "white",
        padding: "24px",
      }}
    >
      {!hideFilterStats && (
        <Grid
          container
          spacing={3}
          style={{
            textAlign: "center",
            fontFamily: `'Courier New', Courier, monospace`,
            fontSize: "14px",
            lineHeight: 1.5,
            backgroundColor: "#f5f5f5",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            whiteSpace: "pre-wrap",
            fontWeight: isOpen ? "" : "bold",
          }}
        >
          {["Designs", "Stocks", "Gross", "Net"].map((label, index) => (
            <Grid
              item
              xs={6} // Two items per row on mobile
              sm={6} // Two items per row on small screens (tablets)
              md={2} // Four items per row on medium screens (desktops)
              lg={2}
              key={index}
            >
              {label}
              <p
                className={classes.statTextValue}
                style={{
                  color: formatWeight(metaInfo["totalGrossWeight"])?.match("kg")
                    ? "green"
                    : enums.colorTheme.primary,
                }}
              >
                {metaInfo[
                  label.toLowerCase() === "designs"
                    ? "uniqueDesigns"
                    : label.toLowerCase() === "stocks"
                    ? "uniqueStocks"
                    : label.toLowerCase() === "gross"
                    ? "totalGrossWeight"
                    : "totalNetWeight"
                ]
                  ? label.toLowerCase() === "designs"
                    ? formatNumberToRead(metaInfo["uniqueDesigns"])
                    : label.toLowerCase() === "stocks"
                    ? formatNumberToRead(metaInfo["uniqueStocks"])
                    : label.toLowerCase() === "gross"
                    ? formatWeight(metaInfo["totalGrossWeight"])
                    : formatWeight(metaInfo["totalNetWeight"])
                  : 0}{" "}
              </p>
            </Grid>
          ))}

          {/* Reset Button */}
          <Grid
            item
            xs={6} // Full width on mobile (two fields per row, placed below)
            sm={3} // 3 columns on small screens
            md={2} // Four items per row on medium screens (desktops)
            lg={2}
            container
            justify="center"
          >
            {!isOpen && checkDirty(getFieldFilters(condition)) && (
              <MyHoverIcons
                muiIcon={
                  <CancelScheduleSendOutlinedIcon
                    style={{
                      fontSize: "48px",
                    }}
                  />
                }
                muiIconHover={
                  <CancelScheduleSendIcon
                    style={{
                      fontSize: "48px",
                    }}
                  />
                }
                tooltip={"Reset"}
                onClick={onReset}
              />
            )}
          </Grid>

          {/* Filter Icon */}
          <Grid
            item
            xs={6} // Full width on mobile (two fields per row, placed below)
            sm={3} // 3 columns on small screens
            md={2} // Smaller size on medium screens
            lg={2}
          >
            <DEFilterIcon isOpen={isOpen} setIsOpened={setIsOpened} />
          </Grid>
        </Grid>
      )}

      <Grid
        container
        spacing={2}
        style={{ marginTop: hideFilterStats ? "0" : "24px" }}
      >
        {isOpen ? (
          !condition?.imageFastSearch ? (
            <>
              <Grid item lg={4}>
                <TextField
                  label={"Design Number / SKU"}
                  onChange={(e) => {
                    if (
                      e.target.value?.toString().trim() &&
                      e.target.value
                        .split(",")
                        .filter((text) => text?.toString().trim()).length
                    ) {
                      setCondition({
                        ...condition,
                        searchText: e.target.value,
                      });
                    } else {
                      setCondition({ ...condition, searchText: "" });
                    }
                  }}
                  value={condition.searchText || ""}
                  placeholder="DESIGN1, SKU1, design2, sku2..."
                  variant="outlined"
                  style={{ width: "100%", color: enums.colorTheme.primary }}
                />
                {printingStationScreen && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!condition.flexibleSearchText}
                        style={{ color: "#e3554b" }}
                        onChange={(event) => {
                          setCondition({
                            ...condition,
                            flexibleSearchText: event.target.checked,
                          });
                        }}
                      />
                    }
                    label={"Partial DESIGN / SKU Search"}
                  />
                )}
              </Grid>

              <Grid item lg={2}>
                <MyAutocomplete
                  // autoSelect={true}
                  multiple
                  options={purities}
                  label={"Purity"}
                  onChange={(_, v) => {
                    setCondition({ ...condition, purities: v });
                  }}
                  value={condition.purities || []}
                  getOptionLabel={(opt) => (opt.value ? opt.value + " KT" : "")}
                />
              </Grid>

              <Grid item lg={2}>
                <MyAutocomplete
                  // autoSelect={true}
                  multiple
                  options={vendorCodes.filter((e) => e)}
                  label={"Vendor Code"}
                  onChange={(_, v) => {
                    setCondition({ ...condition, vendorCodes: v });
                  }}
                  getOptionLabel={(opt) => opt || ""}
                  value={condition.vendorCodes || []}
                />
              </Grid>

              <Grid item lg={2}>
                <MyAutocomplete
                  // autoSelect={true}
                  multiple
                  options={zones.filter((e) => e)}
                  label={"Zone"}
                  getOptionLabel={(opt) => opt || ""}
                  value={condition.zones || []}
                  onChange={(_, value) =>
                    setCondition({
                      ...condition,
                      zones: value,
                    })
                  }
                />
              </Grid>

              <Grid item lg={2}>
                <MyAutocomplete
                  // autoSelect={true}
                  multiple
                  options={Object.values(enums.stockStatus)}
                  value={condition.stockStatuses || []}
                  label={"Stock Status"}
                  onChange={(_, v) =>
                    setCondition({ ...condition, stockStatuses: v })
                  }
                />
              </Grid>

              <Grid item lg={2}>
                <MyAsyncAutocomplete
                  // autoSelect={true}
                  multiple
                  apiUrl={enums.models["data-engine"].category + "/search"}
                  getOptionLabel={(op) => op?.name || ""}
                  value={condition.categories || []}
                  label={"Category"}
                  onChange={(_, v) =>
                    setCondition({ ...condition, categories: v })
                  }
                  apiMethod={"post"}
                />
              </Grid>

              <Grid item lg={2}>
                <MyAsyncAutocomplete
                  // autoSelect={true}
                  multiple
                  apiUrl={enums.models["data-engine"].subCategory + "/search"}
                  getOptionLabel={(op) => op?.name || ""}
                  value={condition.subCategories || []}
                  label={"Sub Category"}
                  onChange={(_, v) =>
                    setCondition({ ...condition, subCategories: v })
                  }
                  apiMethod={"post"}
                  includeOption={{ none: true }}
                />
              </Grid>
              <Grid item lg={2}>
                <MyAsyncAutocomplete
                  // autoSelect={true}
                  multiple
                  apiUrl={
                    enums.models["data-engine"].collectionLines + "/search"
                  }
                  getOptionLabel={(op) => op?.name || ""}
                  value={condition.collectionLines || []}
                  label={"Collection Line"}
                  onChange={(e, v) =>
                    setCondition({ ...condition, collectionLines: v })
                  }
                  apiMethod={"post"}
                  includeOption={{ none: true }}
                />
              </Grid>

              <Grid item lg={isVendor ? 4 : 3} style={{ textAlign: "center" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!condition.imageFlag}
                      style={{ color: "#1d9bc2" }}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setCondition({
                            ...condition,
                            imageFlag: true,
                            withoutImageFlag: false,
                          });
                        } else {
                          setCondition({
                            ...condition,
                            imageFlag: false,
                          });
                        }
                      }}
                    />
                  }
                  label={"With Image"}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!condition.withoutImageFlag}
                      style={{ color: "#1d9bc2" }}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setCondition({
                            ...condition,
                            withoutImageFlag: true,
                            imageFlag: false,
                          });
                        } else {
                          setCondition({
                            ...condition,
                            withoutImageFlag: false,
                          });
                        }
                      }}
                    />
                  }
                  label={"Without Image"}
                />
              </Grid>

              {isVendor ? null : (
                <Grid item lg={1} style={{ textAlign: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!condition.locked}
                        style={{ color: "#1d9bc2" }}
                        onChange={(event) => {
                          setCondition({
                            ...condition,
                            locked: event.target.checked,
                          });
                        }}
                      />
                    }
                    label={"Locked"}
                  />
                </Grid>
              )}

              <Grid item lg={2} style={{ textAlign: "center" }}>
                {printingStationScreen ? null : (
                  <FormControlLabel
                    control={
                      <Switch
                        // sx={{ m: 5 }}
                        checked={!stockWise}
                        onChange={(e) => {
                          setStockWise(!e.target.checked);
                        }}
                        style={{ color: !stockWise ? "#1d9bc2" : "black" }}
                      />
                    }
                    style={{ color: !stockWise ? "#1d9bc2" : "black" }}
                    label="Unique Designs"
                  />
                )}
              </Grid>

              <Grid
                container
                spacing={2}
                alignItems="center"
                style={{ padding: "24px" }}
              >
                {[
                  { label: "Gross Weight", field: "grossWeight" },
                  { label: "Net Weight", field: "netWeight" },
                  { label: "Diamond Carat", field: "diamondCarat" },
                ].map(({ label, field }) => (
                  <Grid item xs={12} sm={4} key={field}>
                    <div style={{ textAlign: "center", marginBottom: "8px" }}>
                      {label}
                    </div>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          label="From"
                          fullWidth
                          type="number"
                          value={condition[field]?.from || ""}
                          onChange={(e) =>
                            setCondition({
                              ...condition,
                              [field]: {
                                ...condition[field],
                                from: e.target.value,
                              },
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="To"
                          fullWidth
                          type="number"
                          value={condition[field]?.to || ""}
                          onChange={(e) =>
                            setCondition({
                              ...condition,
                              [field]: {
                                ...condition[field],
                                to: e.target.value,
                              },
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              {enums.packageActions && (
                <>
                  <Grid item lg={2}>
                    <MyAsyncAutocomplete
                      // autoSelect={true}
                      multiple
                      apiUrl={enums.models["data-engine"].users + "/search"}
                      getOptionLabel={(op) =>
                        op?.firstName
                          ? (op?.firstName || "") + " " + (op?.lastName || "")
                          : ""
                      }
                      value={condition.customers || []}
                      label={"Customer"}
                      onChange={(_, v) => {
                        setCondition({
                          ...condition,
                          customers: v,
                          employees: [],
                        });
                      }}
                      apiMethod={"post"}
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <MyAsyncAutocomplete
                      // autoSelect={true}
                      multiple
                      apiUrl={enums.models.iam.users + "/search"}
                      getOptionLabel={(op) =>
                        op?.firstName
                          ? `${op?.firstName || ""} ${op?.lastName || ""}`
                          : ""
                      }
                      value={condition.employees || []}
                      label={"Employee"}
                      groupBy={(option) => option.role}
                      sortBy={"role"}
                      onChange={(_, v) => {
                        setCondition({
                          ...condition,
                          employees: v,
                          customers: [],
                        });
                      }}
                      apiMethod={"post"}
                    />
                  </Grid>

                  <Grid item lg={2}>
                    <MyAutocomplete
                      // autoSelect={true}
                      options={Object.values(enums.packageActions)}
                      value={condition.packageAction || ""}
                      label={"Package Action"}
                      onChange={(_, v) =>
                        setCondition({ ...condition, packageAction: v })
                      }
                    />
                  </Grid>

                  <Grid item lg={3}>
                    <DateRangePickerModal
                      iconSize={"48px"}
                      openDatePicker={openPackageDatePicker}
                      setOpenDatePicker={setOpenPackageDatePicker}
                      onSubmit={(dateRange) => {
                        setCondition({
                          ...condition,
                          packageDate: dateRange,
                        });
                        setSelectedDateRange(dateRange);
                      }}
                      style={{ color: "#1d9bc2" }}
                      enableAction={{ clear: true }}
                      setSelectedDateRange={setSelectedDateRange}
                      selectedDateRange={selectedDateRange}
                      label={"Packaging Date"}
                      actionName="Apply"
                      actionStyle={{
                        backgroundColor: enums.colorTheme.primary,
                      }}
                    />
                  </Grid>
                </>
              )}

              {printingStationScreen ? null : (
                <CortexSearch
                  files={files}
                  setFiles={setFiles}
                  condition={condition}
                  setCondition={setCondition}
                />
              )}

              <FilterActions />
            </>
          ) : (
            <>
              {printingStationScreen ? null : (
                <CortexSearch
                  files={files}
                  setFiles={setFiles}
                  condition={condition}
                  setCondition={setCondition}
                />
              )}

              <FilterActions />
            </>
          )
        ) : (
          <Grid
            item
            lg={12}
            container
            justify="center"
            // style={{ background: "grey" }}
          >
            <ImageZoomContainer files={files} loading={loading} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const ImageZoomContainer = ({ files, loading }) => {
  const [isHovered, setIsHovered] = useState(false);

  if (!files?.length) return null;

  // Customizable dimensions
  const circleWidth = 280; // Width of the magnifying glass circle
  const circleHeight = 280; // Height of the magnifying glass circle
  const handleLength = 200; // Length of the handle
  const handleThickness = 20; // Thickness of the handle
  const barColor = commonEnums.colorTheme.primary; //"rgba(100, 100, 255, 0.8)";
  const glassColor = commonEnums.colorTheme.primary; // "rgba(100, 100, 255, 0.6)";

  return (
    <div
      style={{
        position: "relative",
        width: `${circleWidth + handleLength}px`, // Total width for horizontal layout
        height: `${circleHeight}px`, // Height of the container
        margin: "auto", // Center horizontally
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 100,
      }}
    >
      {/* Glass Circle */}
      <div
        style={{
          position: "relative",
          transform: isHovered ? "scale(1.5)" : "scale(1)",
          width: `${circleWidth}px`,
          height: `${circleHeight}px`,
          borderRadius: "50%",
          overflow: "hidden",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
          border: `4px solid ${glassColor}`, // Glass border
          backgroundColor: "white", // Optional background for better glass effect
          animation: loading
            ? "search-animation 2s infinite ease-in-out"
            : "none", // Add animation only when loading
          transition: "transform 0.2s ease-in-out",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img
          src={URL.createObjectURL(files[0])}
          alt={files[0].name}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover", // Ensures the image fills the circle without distortion
          }}
        />
      </div>

      {/* Handle (Holder) */}
      <div
        style={{
          width: `${handleLength}px`, // Length of the horizontal handle
          height: `${handleThickness}px`, // Thickness of the handle
          backgroundColor: barColor, // Handle color
          marginLeft: `-${handleThickness / 2}px`, // Overlap with the circle
          borderRadius: `${handleThickness / 2}px`, // Rounded edges for a polished look
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
        }}
      />
      {/* CSS Keyframes for animation */}
      <style>
        {`
          @keyframes search-animation {
            0%, 100% {
              transform: translateX(0px);
            }
            50% {
              transform: translateX(20px);
            }
          }
        `}
      </style>
    </div>
  );
};

const CortexSearch = ({ files, setFiles, condition, setCondition }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const handleInferenceImage = async (files) => {
    // let formData = new FormData();

    // if (files[0]?.type?.includes("image")) {
    //   const imageFile = await resizeFile(files[0]);
    //   formData.append("images", imageFile);
    // }

    if (files[0]) {
      setCondition({ ...condition, inferenceFormData: files[0] });
    }
  };

  return (
    <Grid
      item
      lg={8}
      style={{ position: "relative", textAlign: "center" }}
      container
      spacing={2}
    >
      <Grid
        item
        lg={2}
        onClick={() => {
          if (!subscription.cortex) {
            Emitter.emit("alert_error", "Subscribe Cortex to Unlock");
          }
        }}
      >
        <DocUpload
          accept={"image/*"}
          files={files}
          setFiles={setFiles}
          onFileSelect={handleInferenceImage}
          tooltip={"Search by Image"}
          uploadIcon={{
            type: "Component",
            icon: ImageSearchIcon,
            style: {
              fontSize: "48px",
              position: "relative",
              zIndex: 1,
              color: subscription.cortex ? "#1d9bc2" : "",
            }, // Ensure the upload icon stays above other elements
          }}
          disabled={!subscription.cortex}
        />
        {files.length > 0 ? (
          <React.Fragment>
            <div
              style={{
                marginLeft: "24px",
                position: "absolute",
                top: 0, // Positioned above the upload icon
                left: "10%", // Centered horizontally
                transform: "translateX(-50%)",
                backgroundColor: "#387fc9",
                color: "white",
                padding: "4px",
                // borderRadius: "5px",
                padding: "12px",
                fontSize: "12px",
                fontWeight: "bold",
                maxWidth: "150px",
                textAlign: "center",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                zIndex: 1, // Ensure the filename is above the upload icon
              }}
            >
              {files[0].name.length > 25
                ? `${files[0].name.substring(0, 25)}...`
                : files[0].name}
              <Tooltip title="Remove">
                <button
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    background: "black",
                    color: "white",
                    // border: "none",
                    // borderRadius: "50%",
                    width: "16px",
                    height: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    fontSize: "12px",
                    lineHeight: "12px",
                    padding: 0,
                  }}
                  onClick={() => {
                    const tCon = { ...condition };
                    delete tCon.inferenceFormData;

                    setCondition(tCon);
                    setFiles([]);
                  }}
                >
                  &times;
                </button>
              </Tooltip>
            </div>
          </React.Fragment>
        ) : subscription.cortex ? null : (
          <div
            style={{
              marginLeft: "24px",
              position: "absolute",
              top: 0, // Positioned above the upload icon
              left: "10%", // Centered horizontally
              transform: "translateX(-50%)",
              // backgroundColor: "white",
              color: "black",
              padding: "4px",
              // borderRadius: "5px",
              padding: "12px",
              fontSize: "12px",
              fontWeight: "bold",
              maxWidth: "150px",
              textAlign: "center",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              zIndex: 5, // Ensure the filename is above the upload icon
            }}
          >
            <MyHoverIcons
              muiIcon={<LockIcon />}
              muiIconHover={
                <LockOpenIcon style={{ color: enums.colorTheme.primary }} />
              }
              tooltip={"Subscribe Cortex to Unlock"}
            />
          </div>
        )}
      </Grid>
      <Grid item lg={4}>
        {subscription.cortex ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={!!condition.imageFastSearch}
                style={{ color: "#e3554b" }}
                onChange={(event) => {
                  setCondition({
                    ...condition,
                    imageFastSearch: event.target.checked,
                  });
                }}
              />
            }
            label={"quick filter: IMAGES"}
          />
        ) : null}
      </Grid>
      <Grid item lg={6}>
        {files.length ? (
          <div
            style={{
              // position: "absolute",
              // top: "100%",
              // left: "60%", // Adjusted position to the right of the upload icon
              transform: `scale(${isHovered ? 1.8 : 1})`,
              // maxWidth: "420px",
              // maxHeight: "220px",

              // overflow: "hidden",
              // border: "1px solid #ddd",
              transition: "transform 0.2s ease-in-out",
              // zIndex: 1111,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img
              src={URL.createObjectURL(files[0])}
              alt={files[0].name}
              style={{
                padding: "16px",
                backgroundColor: "white",
                width: "186px",
                height: "186px",
                borderRadius: "5px",
                objectFit: "cover",
                flexShrink: 0,
              }}
            />
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
};

export const getFieldFilters = (currentCondition = {}) => {
  currentCondition = _.cloneDeep(currentCondition || {});

  let filterCriteria = {
    searchText: currentCondition.searchText?.trim() || undefined,
    stockStatuses: currentCondition.stockStatuses?.length
      ? currentCondition.stockStatuses
      : undefined,

    categories: currentCondition.categories?.length
      ? currentCondition.categories.map((v) => ({ id: v._id }))
      : undefined,
    subCategories: currentCondition.subCategories?.length
      ? currentCondition.subCategories.map((v) => ({ id: v._id }))
      : undefined,
    collectionLines: currentCondition.collectionLines?.length
      ? currentCondition.collectionLines.map((v) => ({ id: v._id }))
      : undefined,
    purities: currentCondition.purities?.length
      ? currentCondition.purities.map((v) => ({ value: v.value }))
      : undefined,
    vendorCodes: currentCondition.vendorCodes?.length
      ? currentCondition.vendorCodes
      : undefined,
    zones: currentCondition.zones?.length ? currentCondition.zones : undefined,
    grossWeight:
      currentCondition.grossWeight?.from || currentCondition.grossWeight?.to
        ? {
            from: currentCondition.grossWeight?.from,
            to: currentCondition.grossWeight?.to,
          }
        : undefined,
    netWeight:
      currentCondition.netWeight?.from || currentCondition.netWeight?.to
        ? {
            from: currentCondition.netWeight?.from,
            to: currentCondition.netWeight?.to,
          }
        : undefined,
    totalDiamondWeight:
      currentCondition.diamondCarat?.from || currentCondition.diamondCarat?.to
        ? {
            from: currentCondition.diamondCarat?.from,
            to: currentCondition.diamondCarat?.to,
          }
        : undefined,
    imageFlag:
      currentCondition.imageFlag === true
        ? true
        : currentCondition.withoutImageFlag === true
        ? false
        : undefined,
    locked: currentCondition.locked || undefined,
    inferenceFormData: currentCondition.inferenceFormData || undefined,
    imageFastSearch: currentCondition.imageFastSearch || undefined,
    employees: currentCondition.employees?.length
      ? currentCondition.employees.map((v) => ({ id: v._id }))
      : undefined,
    customers: currentCondition.customers?.length
      ? currentCondition.customers.map((v) => ({ id: v._id }))
      : undefined,
    packageAction: currentCondition.packageAction || undefined,
    packageDate: currentCondition.packageDate?.startDate
      ? {
          from: moment(currentCondition.packageDate.startDate)
            .startOf("day")
            .toISOString(),
          to: moment(currentCondition.packageDate.endDate)
            .endOf("day")
            .toISOString(),
        }
      : undefined,
  };

  // Remove keys with undefined values
  filterCriteria = Object.fromEntries(
    Object.entries(filterCriteria).filter(
      ([_, value]) =>
        value !== undefined && // Remove undefined
        !(Array.isArray(value) && value.length === 0) // Remove empty arrays
    )
  );

  return filterCriteria;
};

const DEFilterIcon = ({ setIsOpened, isOpen }) => {
  return (
    <MyHoverIcons
      tooltip={"Filter"}
      muiIcon={
        isOpen ? (
          <FilterAltOutlinedIcon
            style={{ color: "#1d9bc2", fontSize: "48px" }}
          />
        ) : (
          <FilterAltIcon style={{ color: "#1d9bc2", fontSize: "48px" }} />
        )
      }
      muiIconHover={
        <FilterAltIcon style={{ color: "#1d9bc2", fontSize: "48px" }} />
      }
      onClick={() => setIsOpened(!isOpen)}
    />
  );
};

export const getMongooseCriteria = (currentCondition) => {
  currentCondition = _.cloneDeep(currentCondition || {});

  let filterCriteria = {};
  const searchText = currentCondition?.searchText?.toString().trim();

  if (searchText) {
    filterCriteria["$or"] = [];

    const searchElements = searchText
      .split(",")
      .map((ele) => ele?.toString().trim())
      .filter((e) => e);

    if (searchElements.length) {
      filterCriteria["$or"] = [
        ...searchElements.map((val) => ({
          styleNo: currentCondition.flexibleSearchText
            ? {
                $regex: regexPurify(val),
                $options: "i", // Case-insensitive option
              }
            : val,
        })),
        ...searchElements.map((val) => ({
          sku: currentCondition.flexibleSearchText
            ? {
                $regex: regexPurify(val),
                $options: "i", // Case-insensitive option
              }
            : val,
        })),
      ];
    }
  }

  if (currentCondition?.stockStatuses?.length) {
    filterCriteria.stockStatus = { $in: currentCondition.stockStatuses };
  }

  if (currentCondition?.categories?.length) {
    filterCriteria["category._id"] = {
      $in: currentCondition.categories.map((ele) => ele._id),
    };
  }
  if (currentCondition?.subCategories?.length) {
    filterCriteria["subCategory._id"] = {
      $in: currentCondition.subCategories.map((ele) => ele._id),
    };
  }
  if (currentCondition?.collectionLines?.length) {
    filterCriteria["collectionLine._id"] = {
      $in: currentCondition.collectionLines.map((ele) => ele._id),
    };
  }

  if (currentCondition?.purities?.length) {
    filterCriteria["purity.value"] = {
      $in: currentCondition.purities.map((ele) => ele.value),
    };
  }
  if (currentCondition?.vendorCodes?.length) {
    currentCondition.vendorCodes.forEach((ele, eleIndex) => {
      if (ele === empty) {
        currentCondition.vendorCodes[eleIndex] = null;
        currentCondition.vendorCodes.push(...["", undefined]);
      }
    });
    filterCriteria["vendorCode"] = { $in: currentCondition.vendorCodes };
  }

  if (currentCondition?.zones?.length) {
    currentCondition.zones.forEach((ele, eleIndex) => {
      if (ele === empty) {
        currentCondition.zones[eleIndex] = null;
        currentCondition.zones.push(...["", undefined]);
      }
    });
    filterCriteria["zone"] = { $in: currentCondition.zones };
  }

  if (currentCondition?.grossWeight?.from?.toString()?.trim()) {
    filterCriteria["grossWeight"] = {
      $gte: Number(currentCondition?.grossWeight?.from),
    };
  }
  if (currentCondition?.grossWeight?.to?.toString()?.trim()) {
    filterCriteria["grossWeight"] = {
      ...filterCriteria["grossWeight"],
      $lte: Number(currentCondition?.grossWeight?.to),
    };
  }
  if (currentCondition?.netWeight?.from?.toString()?.trim()) {
    filterCriteria["netWeight"] = {
      $gte: Number(currentCondition?.netWeight?.from),
    };
  }
  if (currentCondition?.netWeight?.to?.toString()?.trim()) {
    filterCriteria["netWeight"] = {
      ...filterCriteria["netWeight"],
      $lte: Number(currentCondition?.netWeight?.to),
    };
  }
  if (currentCondition?.diamondCarat?.from?.toString()?.trim()) {
    filterCriteria["totalDiamondWeight"] = {
      $gte: Number(currentCondition?.diamondCarat?.from),
    };
  }
  if (currentCondition?.diamondCarat?.to?.toString()?.trim()) {
    filterCriteria["totalDiamondWeight"] = {
      ...filterCriteria["totalDiamondWeight"],
      $lte: Number(currentCondition?.diamondCarat?.to),
    };
  }

  if (currentCondition?.imageFlag) {
    filterCriteria.imageFlag = true;
  }
  if (currentCondition?.withoutImageFlag) {
    filterCriteria.imageFlag = { $ne: true };
  }
  if (!(currentCondition.imageFlag || currentCondition.withoutImageFlag)) {
    delete filterCriteria.imageFlag;
  }

  if (
    currentCondition?.packageDate?.startDate ||
    currentCondition?.packageDate?.endDate
  ) {
    const startOfDay = moment(currentCondition.packageDate?.startDate)
      .startOf("day")
      .toString();
    const endOfDay = moment(currentCondition.packageDate?.endDate)
      .endOf("day")
      .toString();

    filterCriteria["packagingHistory.dateCreated"] = {
      $gte: startOfDay,
      $lte: endOfDay,
    };
  }

  if (currentCondition?.packageAction) {
    filterCriteria = {
      ...filterCriteria,
      $expr: {
        $and: [
          ...(filterCriteria.$expr?.$and || []),
          {
            $eq: [
              { $arrayElemAt: ["$packagingHistory.action", -1] },
              currentCondition?.packageAction,
            ],
          },
        ],
      },
    };
  }
  if (currentCondition?.employees?.length) {
    filterCriteria = {
      ...filterCriteria,

      "packagingHistory.assignee._id": {
        $in: currentCondition.employees.map((ele) => ele._id),
      },
    };
  }
  if (currentCondition?.customers?.length) {
    filterCriteria = {
      ...filterCriteria,

      "packagingHistory.assignee._id": {
        $in: currentCondition.customers.map((ele) => ele._id),
      },
    };
  }

  if (currentCondition?.locked) {
    filterCriteria = {
      ...filterCriteria,
      "lockedFrom.orderPro": { $exists: true },
    };
  }

  return filterCriteria;
};

export default FilterByFields;
