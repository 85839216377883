import React, { useEffect, useState } from "react";
import backButton from "@ui/assets/data-engine/assets/commonIcons/back.png";
import addIcon from "@ui/assets/data-engine/assets/commonIcons/add.png";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Grid, Paper } from "@material-ui/core/index";
import Tooltip from "@mui/material/Tooltip";
import { useFormContext } from "react-hook-form";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import { useFieldArray } from "react-hook-form";
import enums from "helpers/enums";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import Label from "@ui/components/Label";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { joinWithSeparator } from "@ui/Utils/helper";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";

const DiamondDetails = ({ setOpenDiamondDetails, isEditable }) => {
  const { control, getValues } = useFormContext();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `diamondDetails`,
  });

  // useEffect(() => {
  //   (async () => {
  //     try {
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   })();
  // }, []);

  const handleGoBack = () => {
    let invalidFlag = false;
    const diamondDetails = getValues("diamondDetails");

    diamondDetails.map((diamond, diamondIndex) => {
      const isInvalid = {
        diamondQuantity:
          isNaN(diamond.diamondQuantity) ||
          !diamond.diamondQuantity ||
          diamond.diamondQuantity <= 0 ||
          Math.floor(diamond.diamondQuantity) != diamond.diamondQuantity,

        totalCarat:
          isNaN(diamond.totalCarat) ||
          !diamond.totalCarat ||
          diamond.totalCarat <= 0,
      };

      if (
        isInvalid.diamondQuantity ||
        isInvalid.totalCarat ||
        !diamond.shape
        // ||
        // !diamond.clarity ||
        // !diamond.color
      ) {
        invalidFlag = true;
        const reqFields = joinWithSeparator(
          [
            isInvalid.diamondQuantity ? "Diamond Quantity" : null,
            isInvalid.totalCarat ? `Total Carat` : null,
            !diamond.shape ? "Shape" : null,
            // !diamond.color ? "Color" : null,
            // !diamond.clarity ? "Clarity" : null,
          ].filter((d) => d)
        );

        CustomEventEmitter.emit(
          "alert_error",
          `Fix #${diamondIndex + 1} to continue << ${reqFields} >>`
        );
      }
    });

    if (!invalidFlag) {
      setOpenDiamondDetails(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <img
          src={backButton}
          style={{ height: "50px", width: "50px", cursor: "pointer" }}
          onClick={handleGoBack}
        />
      </Grid>
      <Grid item xs={10} container justify="center">
        <Grid item xs={6}>
          <h2>Diamond Details</h2>
        </Grid>

        <Grid item xs={1}>
          {isEditable ? (
            <Tooltip title={"Add Diamond Detail"}>
              <img
                src={addIcon}
                style={{
                  height: "50px",
                  width: "50px",
                  cursor: "pointer",
                  marginTop: "24px",
                }}
                onClick={() => append({ diamondQuantity: 1 })}
              />
            </Tooltip>
          ) : null}
        </Grid>
      </Grid>
      <Grid item lg={12}>
        {fields.length ? (
          fields.map((diamond, diamondIndex) => (
            <div style={{ padding: "8px" }}>
              <div style={{ fontWeight: "bold" }}># {diamondIndex + 1}</div>
              <DiamondFields
                key={diamondIndex}
                diamondIndex={diamondIndex}
                isEditable={isEditable}
                diamond={diamond}
                remove={remove}
              />
            </div>
          ))
        ) : isEditable ? (
          <Grid
            item
            lg={10}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <h3>Add Diamond Detail</h3>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

const DiamondFields = ({ isEditable, remove, diamondIndex }) => {
  const { watch, setValue } = useFormContext();
  const diamond = watch(`diamondDetails[${diamondIndex}]`);
  const [isSolitaire, setIsSolitaire] = useState(false);
  const [diamondColorOptions, setDiamondColorOptions] = useState([]);
  const [isRoundDiamond, setIsRoundDiamond] = useState(false);

  useEffect(() => {
    setDiamondColorOptions(
      isSolitaire
        ? Object.values(enums.diamondColorOptions.isSolitaire)
        : Object.values(enums.diamondColorOptions.nonSolitaire)
    );
  }, [isSolitaire]);

  useEffect(() => {
    setIsRoundDiamond(
      diamond.shape === enums.diamondMasterData.diamondType.round
    );
  }, [diamond.shape]);

  useEffect(() => {
    if (isRoundDiamond && isEditable) {
      const isSolitaire = !!(
        (Number(diamond.totalCarat) || 0) /
          (Number(diamond.diamondQuantity) || 1) >=
        0.3
      );

      if (isSolitaire) {
        const value = diamond.solitaireSize?.length || 0;

        setValue(`diamondDetails[${diamondIndex}].solitaireSize`, {
          length: value,
          width: value,
        });
      } else {
        const value = diamond.sieveSize?.length || 0;

        setValue(`diamondDetails[${diamondIndex}].sieveSize`, {
          length: value,
          width: value,
        });
      }
    }
  }, [
    isRoundDiamond,
    isSolitaire,
    diamond.sieveSize?.length,
    diamond.sieveSize?.width,
    diamond.solitaireSize?.length,
    diamond.solitaireSize?.width,
  ]);

  const setAndHandleIsSolitaire = ({ diamondQuantity, totalCarat }) => {
    if (totalCarat) {
      diamondQuantity = diamond.diamondQuantity || 1;
    } else {
      totalCarat = diamond.totalCarat || 0;
    }

    const isSolitaire = !!(
      (Number(totalCarat) || 0) / (Number(diamondQuantity) || 1) >=
      0.3
    );

    const updateObj = {
      ...diamond,
      isSolitaire,
      diamondQuantity,
      totalCarat,
    };

    setIsSolitaire(isSolitaire);
    setValue(`diamondDetails[${diamondIndex}]`, updateObj);
  };

  const GradingLab = () => {
    // 10 April, 17:03
    return (
      <Grid item lg={2}>
        {isEditable ? (
          <RHAutoComplete
            name={`diamondDetails[${diamondIndex}].gradingLab`}
            label="Grading Lab"
            options={enums.diamondMasterData.gradingLab}
          />
        ) : (
          <Label label="Grading Lab" value={diamond?.gradingLab} />
        )}
      </Grid>
    );
  };

  return (
    <Paper
      style={{
        padding: "16px",
        marginTop: "16px",
        marginBottom: "8px",
        backgroundColor: "#d2f3fc",
      }}
    >
      <Grid item lg={12} container spacing={2} key={diamond.id}>
        <Grid item lg={2}>
          {isEditable ? (
            <RHTextField
              name={`diamondDetails[${diamondIndex}].diamondQuantity`}
              label="Diamond Quantity"
              type="number"
              onChange={(e) =>
                setAndHandleIsSolitaire({ diamondQuantity: e.target.value })
              }
            />
          ) : (
            <Label label="Diamond Quantity" value={diamond?.diamondQuantity} />
          )}
        </Grid>

        <Grid item lg={2}>
          {isEditable ? (
            <RHTextField
              name={`diamondDetails[${diamondIndex}].totalCarat`}
              label="Total Carat (cts)"
              type="number"
              onChange={(e) =>
                setAndHandleIsSolitaire({ totalCarat: e.target.value })
              }
            />
          ) : (
            <Label label="Total Carat (cts)" value={diamond?.totalCarat} />
          )}
        </Grid>

        <Grid item lg={2}>
          {isEditable ? (
            <RHAutoComplete
              name={`diamondDetails[${diamondIndex}].shape`}
              label="Shape"
              options={enums.diamondMasterData.shape}
            />
          ) : (
            <Label label="Shape" value={diamond?.shape} />
          )}
        </Grid>

        <Grid item lg={2}>
          {isEditable ? (
            <RHAutoComplete
              name={`diamondDetails[${diamondIndex}].color`}
              label="Color"
              options={diamondColorOptions}
            />
          ) : (
            <Label label="Color" value={diamond?.color} />
          )}
        </Grid>

        <Grid item lg={2}>
          {isEditable ? (
            <RHAutoComplete
              name={`diamondDetails[${diamondIndex}].cut`}
              label="Cut"
              options={enums.diamondMasterData.cut}
            />
          ) : (
            <Label label="Cut" value={diamond?.cut} />
          )}
        </Grid>
        <Grid item lg={2}>
          {isEditable ? (
            <RHAutoComplete
              name={`diamondDetails[${diamondIndex}].clarity`}
              label="Clarity"
              options={enums.diamondMasterData.clarity}
            />
          ) : (
            <Label label="Clarity" value={diamond?.clarity} />
          )}
        </Grid>

        {diamond.isSolitaire ? (
          <>
            <Grid item lg={2}>
              {isEditable ? (
                <RHAutoComplete
                  name={`diamondDetails[${diamondIndex}].polish`}
                  label="Polish"
                  options={enums.diamondMasterData.polish}
                />
              ) : (
                <Label label="Polish" value={diamond?.polish} />
              )}
            </Grid>
            <Grid item lg={2}>
              {isEditable ? (
                <RHAutoComplete
                  name={`diamondDetails[${diamondIndex}].symmetry`}
                  label="Symmetry"
                  options={enums.diamondMasterData.symmetry}
                />
              ) : (
                <Label label="Symmetry" value={diamond?.symmetry} />
              )}
            </Grid>
            <Grid item lg={2}>
              {isEditable ? (
                <RHAutoComplete
                  name={`diamondDetails[${diamondIndex}].fluorescenceIntensity`}
                  label="Fluorescence Intensity"
                  options={enums.diamondMasterData.fluorescenceIntensity}
                />
              ) : (
                <Label
                  label="Fluorescence Intensity"
                  value={diamond?.fluorescenceIntensity}
                />
              )}
            </Grid>

            <GradingLab />

            <Grid item lg={5} container spacing={1}>
              <Grid item lg={4}>
                <Label
                  label={"Solitaire Size --> "}
                  noColon={true}
                  labelStyle={{ fontWeight: "bold" }}
                />
              </Grid>
              <Grid item lg={4}>
                {isEditable ? (
                  <RHTextField
                    name={`diamondDetails[${diamondIndex}].solitaireSize.length`}
                    label="Length (mm)"
                    type="number"
                  />
                ) : (
                  <Label
                    label="Length (mm)"
                    value={diamond?.solitaireSize?.length}
                    noColon={true}
                  />
                )}
              </Grid>
              <Grid item lg={4}>
                {isEditable && !isRoundDiamond ? (
                  <RHTextField
                    name={`diamondDetails[${diamondIndex}].solitaireSize.width`}
                    label="Width (mm)"
                    type="number"
                  />
                ) : (
                  <Label
                    label="Width (mm)"
                    value={diamond?.solitaireSize?.width}
                    noColon={true}
                  />
                )}
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <GradingLab />

            <Grid item lg={6} container spacing={1}>
              <Grid item lg={4}>
                <Label
                  label={"Sieve Size --> "}
                  noColon={true}
                  labelStyle={{ fontWeight: "bold" }}
                />
              </Grid>
              <Grid item lg={4}>
                {isEditable ? (
                  <RHTextField
                    name={`diamondDetails[${diamondIndex}].sieveSize.length`}
                    label="Length (mm)"
                    type="number"
                  />
                ) : (
                  <Label
                    label="Length (mm)"
                    value={diamond?.sieveSize?.length}
                    noColon={true}
                  />
                )}
              </Grid>
              <Grid item lg={4}>
                {isEditable && !isRoundDiamond ? (
                  <RHTextField
                    name={`diamondDetails[${diamondIndex}].sieveSize.width`}
                    label="Width (mm)"
                    type="number"
                  />
                ) : (
                  <Label
                    label="Width (mm)"
                    value={diamond?.sieveSize?.width}
                    noColon={true}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}

        <Grid item lg={1}>
          {isEditable ? (
            <MyHoverIcons
              muiIcon={<RemoveCircleIcon style={{ fontSize: "32px" }} />}
              muiIconHover={
                <RemoveCircleOutlineIcon style={{ fontSize: "62px" }} />
              }
              style={{
                cursor: "pointer",
                color: "black",
              }}
              onClick={() => remove(diamondIndex)}
              tooltip={`Remove Diamond ${diamondIndex + 1}`}
            />
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DiamondDetails;
