import React, { useState } from "react";
import MaterialTable from "material-table";
import Card from "./Card";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import {
  downloadImportTemplate,
  handleDataImport,
} from "@ui/Utils/excelImportHelpers";
import { Grid } from "@material-ui/core/index";
import Label from "./Label";

const MatTable = (props) => {
  const {
    title,
    data,
    columns,
    actions = [],
    onRowClick,
    onSelectionChange = () => {},
    tableRef,
    showSelectAllCheckbox,
    excelImport = {
      importQueryParams: "",
      model: "",
      cols: [
        //  key: "firstName",
        // header: "First Name",
        // required: true,
        // unique: true,
        // dataType: enums.dataTypes.number,
        // maxLength: 15,
        // enums: availableGroups,
      ],
      fileName: "",
      customBaseApi: "",
    },
  } = props;

  const [importResult, setImportResult] = useState({});

  const filteredInvalidRows = Object.keys(
    importResult.invalidRows || {}
  ).filter((col) => importResult.invalidRows[col]?.rows?.length);

  const filteredInvalidExcelData = Object.keys(
    importResult.invalidExcelData || {}
  ).filter((col) => importResult.invalidExcelData[col]?.length);

  // Handle template download
  const handleDownloadTemplate = async () => {
    try {
      await downloadImportTemplate(excelImport.fileName, excelImport.cols);
    } catch (err) {
      console.error("Error downloading template:", err);
    }
  };

  // Handle Excel import
  const handleImportTemplate = async (importedFile) => {
    try {
      setImportResult({});

      const res = await handleDataImport(
        excelImport.model,
        importedFile, // Pass the file directly
        excelImport.fileName,
        excelImport.cols,
        excelImport.importQueryParams,
        { customBaseApi: excelImport.customBaseApi }
      );
      setImportResult(res);

      tableRef?.current?.onQueryChange &&
        tableRef.current.onQueryChange({
          page: 0,
        });
    } catch (err) {
      console.error("Error importing data:", err);
    }
  };

  // Add import and download actions
  const importAndDownloadActions = [
    {
      icon: () => (
        <MyHoverIcons
          muiIcon={<DownloadForOfflineOutlinedIcon />}
          muiIconHover={<DownloadForOfflineIcon />}
        />
      ),
      tooltip: "Download Template",
      isFreeAction: true,
      hidden: !excelImport?.cols?.length,
      onClick: handleDownloadTemplate,
    },
    {
      icon: () => (
        <MyHoverIcons
          muiIcon={<UploadFileIcon />}
          muiIconHover={<UploadFileRoundedIcon />}
        />
      ),
      tooltip: "Upload File",
      hidden: !excelImport?.cols?.length,
      isFreeAction: true,
      onClick: () => {
        const fileInput = document.createElement("input"); // Create file input dynamically
        fileInput.type = "file";
        fileInput.accept = ".xlsx, .xls"; // Accept Excel files only

        fileInput.onchange = async (e) => {
          try {
            const selectedFiles = e.target.files; // Get selected files
            if (!selectedFiles || selectedFiles.length === 0) {
              console.error("No file selected.");
              return;
            }

            const selectedFile = selectedFiles[0]; // Get the first file
            console.log("Selected file:", selectedFile);

            // Pass the file to handleImportTemplate directly
            await handleImportTemplate(selectedFile);
          } catch (err) {
            console.error("Error handling file input:", err);
          }
        };

        fileInput.click(); // Trigger the file input dialog
      },
    },
  ];

  return (
    <MaterialTable
      {...props}
      tableRef={tableRef}
      components={{
        Container: (props) => <Card {...props} />,
      }}
      options={{
        headerStyle: {
          fontWeight: 800,
          fontSize: 18,
        },
        actionsColumnIndex: -1,
        search: true,
        pageSize: 10,
        // selection: true,
        // maxBodyHeight: "65vh",
        // minBodyHeight: "65vh",
        showTextRowsSelected: false,
        padding: "dense",
        showSelectAllCheckbox: showSelectAllCheckbox || false,
        paging: true,
        pageSizeOptions: [10, 20, 50, 100],
        ...props.options,
      }}
      localization={{
        pagination: {
          labelRowsPerPage: "\n",
        },
        header: {
          actions: "",
        },
      }}
      actions={[...importAndDownloadActions, ...actions]}
      columns={columns}
      data={data}
      title={
        Object.keys(importResult).length ? (
          <Grid
            item
            xs={12}
            container
            justify="center"
            style={{ padding: "8px" }}
          >
            <Grid item xs={10}>
              <b style={{ color: "#075985" }}>Review</b>
            </Grid>
            {filteredInvalidRows?.length ? (
              <Grid item xs={10}>
                <Label
                  label={"Invalid Rows"}
                  value={
                    <ul>
                      {filteredInvalidRows.map((col, colIndex) => (
                        <li key={colIndex}>
                          <b>{col}: </b>
                          {(importResult.invalidRows[col]?.rows || []).join(
                            ", "
                          )}
                        </li>
                      ))}
                    </ul>
                  }
                />
              </Grid>
            ) : null}
            {filteredInvalidExcelData.length ? (
              <Grid item xs={10}>
                <Label
                  label={"Invalid Excel Rows"}
                  value={
                    <ul>
                      {filteredInvalidExcelData.map((col, colIndex) => (
                        <li key={colIndex}>
                          <b>{col}: </b>
                          {(importResult.invalidExcelData[col] || []).join(
                            ", "
                          )}
                        </li>
                      ))}
                    </ul>
                  }
                />
              </Grid>
            ) : null}
            <Grid item xs={10}>
              <Label label={"Created"} value={importResult.created} />
            </Grid>
            {importResult.updated ? (
              <Grid item xs={10}>
                <Label label={"Updated"} value={importResult.updated} />
              </Grid>
            ) : null}

            {importResult.duplicates?.length ? (
              <Grid item xs={10}>
                <Label
                  label={"Duplicates"}
                  value={importResult.duplicates?.join(", ")}
                />
              </Grid>
            ) : null}

            {importResult.skippedData?.length ? (
              <Grid item xs={10}>
                <Label
                  label={"Skipped Existing phone number duplicates"}
                  value={importResult.skippedData.join(", ")}
                />
              </Grid>
            ) : null}

            <Grid item xs={10}>
              <Label label={"Total"} value={importResult.total} />
            </Grid>
          </Grid>
        ) : (
          title || ""
        )
      }
      onRowClick={onRowClick}
      onSelectionChange={(rows, dataClicked, path) =>
        onSelectionChange(rows, path)
      }
    />
  );
};

export default MatTable;
