import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import axios from "axios";
import { isDivisionControlled, resizeFile } from "./helper";
import commonConfig from "config/commonConfig";
import commonEnums from "./commonEnums";

export const requestHeaders = (additionalHeaders, replaceHeaders) => {
  let token = window.localStorage.getItem("token");
  if (!token || token == "undefined" || token == "null") {
    token = sessionStorage.getItem("token");
  }

  const oneauthlogin = window.localStorage.getItem("oneauthlogin");

  let headers = {};

  if (oneauthlogin === "true") {
    headers.oneauthlogin = true;
  }

  if (token && token !== "undefined" && token !== "null") {
    headers.Authorization = token;
  }

  const sessionDivision = window.sessionStorage.getItem("division");

  if (
    isDivisionControlled ||
    additionalHeaders?.isDivisionControlled ||
    (sessionDivision &&
      !(sessionDivision == "undefined" || sessionDivision == "null"))
  ) {
    delete headers.isDivisionControlled;

    let division = sessionDivision;
    division = division && division !== "undefined" ? JSON.parse(division) : {};
    if (division._id) {
      headers.division = JSON.stringify({
        _id: division._id,
        name: division.name,
      });
    }
  }

  if (replaceHeaders) headers = additionalHeaders;
  else
    headers = {
      ...headers,
      ...additionalHeaders,
    };

  return headers;
};

const get = async (url, additionalHeaders, { replaceHeaders } = {}) => {
  try {
    const headers = requestHeaders(additionalHeaders, replaceHeaders);

    const res = await axios.get(url, { headers });
    return res;
  } catch (err) {
    if (err.response?.status === 401) {
      forceLogout();
    }

    console.log(err?.response?.data?.message);
    throw err;
  }
};

const post = async (url, data, additionalHeaders) => {
  try {
    const headers = requestHeaders(additionalHeaders);

    const res = await axios.post(url, data, { headers });
    return res;
  } catch (err) {
    if (err.response?.status === 401) {
      forceLogout();
    }
    console.log(err?.response?.data?.message);

    throw err;
  }
};

export const put = async (url, data, { additionalHeaders } = {}) => {
  try {
    const headers = requestHeaders(additionalHeaders);

    const response = await axios.put(url, data, { headers });
    return response;
  } catch (error) {
    console.log("Error in put method of HTTP" + error?.response?.data?.message);
    throw error;
  }
};

export const deleteRequest = async (url, data) => {
  try {
    const headers = requestHeaders();
    const response = await axios.delete(url, { headers, data });
    return response;
  } catch (error) {
    console.log(
      "Error in deleteRequest method of HTTP" + error?.response?.data?.message
    );
    throw error;
  }
};

const upload = async (url, files, resize = true, overRideForm) => {
  let response = {},
    headers = requestHeaders();
  if (overRideForm) {
    response = await axios.post(url, overRideForm, {
      headers,
    });
  } else {
    let formData = new FormData();
    formData.append("files", files);

    try {
      for (let i = 0; i < files.length; i++) {
        let invalidFlag = false;
        if (files[i].size > 5242880) {
          // 5mb in bytes...
          invalidFlag = true;
          CustomEventEmitter.emit("Skipped file more than 5mb");
        }

        if (files[i]?.type?.includes("image") && !invalidFlag) {
          const imageFile = resize ? await resizeFile(files[i]) : files[i];
          formData.append("files", imageFile);
        } else if (!invalidFlag) {
          formData.append("files", files[i]);
        }
      }

      if (files.length) {
        response = await axios.post(url, formData, {
          headers,
        });
      }
    } catch (err) {
      console.log(
        "Error in upload method of HTTP" + err?.response?.data?.message
      );
      throw err;
    }
  }

  return response;
};

axios.interceptors.request.use(
  (config) => {
    CustomEventEmitter.emit("progressBar", true);
    // check for valid session / refreshed Token to current session.
    return config;
  },
  (error) => {
    console.error(error.message);
    throw error;
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    CustomEventEmitter.emit("progressBar", false);

    return response;
  },
  (error) => {
    console.error(error.message);
    if (error.response?.status == 401) {
      CustomEventEmitter.emit(
        "alert_error",
        "Session expired. Login to continue..."
      );
      forceLogout();
    }
    CustomEventEmitter.emit("progressBar", false);
    throw error;
  }
);

export const forceLogout = () => {
  let localTag;

  if (commonConfig.appModule === "data-engine") {
    localTag = window.localStorage.getItem(
      commonEnums.localStorage.tagTemplate
    );
    localTag = localTag ? JSON.parse(localTag) : null;
  }

  window.localStorage.clear();
  window.sessionStorage.clear();

  if (localTag) {
    window.localStorage.setItem(
      commonEnums.localStorage.tagTemplate,
      JSON.stringify(localTag)
    );
  }
  window.location.reload();
};

const HTTP = {
  post: post,
  get: get,
  put: put,
  delete: deleteRequest,
  upload: upload,
};

export default HTTP;
