import React, { useEffect, useState } from "react";
import Header from "@ui/ComponentUtils/Header";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import Label from "@ui/components/Label";
import { Grid } from "@material-ui/core/index";
import {
  formatDateToRead,
  getProductImageUri,
  isAdmin,
  isVendor,
} from "@ui/Utils/helper";
import { defaultFilter } from "../Detail";
import { getSingleRecord } from "../../../api";
import Emitter from "@ui/Utils/CustomEventEmitter";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import generatePDF from "@ui/Utils/PDF/generate_pdf";

const DetailHeader = ({ loading, setLoading, initialValues, ...props }) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();
  const { watch } = formMethods;
  const values = watch();
  const model = enums.models["e-creatives"]?.exhibitionQuotations;
  const params = useParams();
  const navigate = useNavigate();

  const [setting, setSetting] = useState({});

  useEffect(() => {
    (async () => {
      try {
        setSetting(
          (await getSingleRecord(enums.models["vendor-portal"]?.settings)) || {}
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + model);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const links = [
    {
      name: "Exhibit it",
      url: "#/" + model,
      isDisplayLink: true,
    },
    {
      name: `${values.quotationNo || ""}`,
      url: "",
      isDisplayLink: false,
    },
  ];

  // Core function to handle API call and PDF generation

  const generatePdfForVendor = async (
    vendorCode,
    vendorItems,
    tempSetting,
    vendorWise,
    zip = null // Add zip as an optional parameter
  ) => {
    let totalGrossWeight = 0,
      totalNetWeight = 0,
      totalQuantity = 0;

    const pdfProducts = vendorItems.map((item) => {
      item = item || {};
      item.product = { ...item.product }; // to show customized values if customized

      totalGrossWeight += (item.product?.grossWeight || 0) * item.quantity;
      totalNetWeight += (item.product?.netWeight || 0) * item.quantity;
      totalQuantity += item.quantity || 0;

      return {
        styleNo: item.product?.styleNo,
        grossWeight: (Number(item.product?.grossWeight) || 0).toFixed(3),
        netWeight: (Number(item.product?.netWeight) || 0).toFixed(3),
        purity: item.purity?.value
          ? item.purity?.value + " KT"
          : item.product?.purity?.value
          ? item.product?.purity?.value + " KT"
          : "",
        color: item.color || item.product?.color,
        adminRemarks: item.remarks,
        dueDate: item?.dueDate ? formatDateToRead(item?.dueDate) : "",
        image: getProductImageUri(item.product || {}, true),
        quantity: item?.quantity,
        motiWeight: item?.product?.motiWeight,
        kundanWeight: item?.product?.kundunWeight,
      };
    });

    const orderDate = values.dateCreated
      ? formatDateToRead(values.dateCreated)
      : "";

    const fileName = `${
      vendorCode === "-" ? "NoVendor_" : `${vendorCode ? vendorCode + "_" : ""}`
    }${values.quotationNo}`;

    const res = await generatePDF(
      {
        data: {
          vendorCode: vendorCode || "-",
          remarks: values.overallRemarks,
          orderId: values.quotationNo,
          orderDate: orderDate,
          products: pdfProducts,
          totalGrossWeight: totalGrossWeight.toFixed(3),
          totalNetWeight: totalNetWeight.toFixed(3),
          totalQuantity: totalQuantity,
          customerName: vendorWise
            ? ""
            : isAdmin
            ? `${values.customer?.firstName} ${values.customer?.lastName} ${
                values.customer?.companyDetails?.name
                  ? `(${values.customer.companyDetails.name})`
                  : ""
              }`
            : "",
        },
        title: "EXHIBIT it",
        selectedTemplate: tempSetting.pdfTemplate,
        appModule: "vendor-portal",
        model: "quotations",
      },
      fileName,
      zip
    );

    if (vendorWise && zip) {
      // If vendorWise is true and zip is provided, add PDF to ZIP

      zip.file(fileName + ".pdf", res, { binary: true });
    }
  };

  // Main function to decide vendor-wise or combined download
  const downloadPdf = async (vendorWise = false) => {
    try {
      setLoading(true);
      const tempSetting = { ...setting };

      if (!tempSetting?._id || !tempSetting?.pdfTemplate) {
        return Emitter.emit(
          "alert_error",
          "Configure PDF Settings to continue"
        );
      }

      const filteredItems = values.items?.filter((item) => defaultFilter(item));

      if (!filteredItems?.length) {
        return Emitter.emit(
          "alert_error",
          "No items available for PDF generation"
        );
      }

      if (vendorWise) {
        // Group items by vendorCode
        const groupedItemsByVendor = filteredItems.reduce((grouped, item) => {
          const vendorCode = item.vendorCode || "-";
          if (!grouped[vendorCode]) {
            grouped[vendorCode] = [];
          }
          grouped[vendorCode].push(item);
          return grouped;
        }, {});

        // Create a new JSZip instance
        const zip = new JSZip();

        // Generate all PDFs and add them to the ZIP
        await Promise.all(
          Object.entries(groupedItemsByVendor).map(
            ([vendorCode, vendorItems]) =>
              generatePdfForVendor(
                vendorCode,
                vendorItems,
                tempSetting,
                vendorWise,
                zip // Pass the zip instance
              )
          )
        );

        // Once all PDFs are added, generate and download the ZIP file
        const zipContent = await zip.generateAsync({ type: "blob" });
        saveAs(zipContent, `VendorWise_${values.quotationNo}.zip`);
      } else {
        // Combine all items in a single PDF
        await generatePdfForVendor("", filteredItems, tempSetting, false);
      }
      Emitter.emit("alert_success", "Download Successful");
    } catch (err) {
      console.error(err);
      Emitter.emit("alert_error", "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const actions = [
    {
      label: "Vendor-wise PDF",
      tooltip: setting.pdfTemplate
        ? "Vendor-wise PDF"
        : "Select PDF Template in Settings",
      iconName: "pdf",
      hidden: isEditable || isVendor,
      disabled: setting.pdfTemplate ? false : true,
      onClick: () => downloadPdf(true),
    },
    {
      label: "Download PDF",
      tooltip: setting.pdfTemplate
        ? "Download PDF"
        : "Select PDF Template in Settings",
      iconName: "pdf",
      hidden: isEditable,
      disabled: setting.pdfTemplate ? false : true,
      onClick: () => downloadPdf(),
    },
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden:
        isEditable ||
        !values.items?.filter((item) => {
          return defaultFilter(item);
        }).length,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  const headerValueStyle = {
    fontWeight: "bold",
  };

  return (
    <div>
      <div>
        {loading ? (
          <h2>
            <pre
              style={{
                color: enums.colorTheme.primary,
                // backgroundColor: "white",
              }}
            >
              Downloading PDF: This will take a moment
            </pre>
          </h2>
        ) : (
          <Header links={links} pageTitle={"EXHIBIT it"} actions={actions} />
        )}
      </div>

      <Grid container spacing={3} style={{ marginBottom: "24px" }}>
        <Grid item xs={3}>
          <Label
            label={"Quotation No"}
            value={values.quotationNo}
            valueStyle={headerValueStyle}
          />
        </Grid>

        {isVendor ? null : (
          <Grid item xs={3}>
            <Label
              label={"Customer"}
              value={
                (values.customer?.firstName || "") +
                " " +
                (values.customer?.lastName || "") +
                ` ${
                  values.customer?.companyDetails?.name
                    ? "(" + values.customer?.companyDetails?.name + ")"
                    : ""
                }`
              }
              valueStyle={headerValueStyle}
            />
          </Grid>
        )}

        <Grid item xs={3}>
          <Label
            label={"Overall Remarks"}
            value={values.overallRemarks}
            valueStyle={headerValueStyle}
          />
        </Grid>

        <Grid item xs={3}>
          <Label
            label={"Order By"}
            value={`${
              (values.createdBy?.firstName || "") +
              " " +
              (values.createdBy?.lastName || "") +
              ` (${values.createdBy?.phone || ""})`
            }`}
            valueStyle={headerValueStyle}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DetailHeader;
