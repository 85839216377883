import React, { Fragment, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Grid, Tooltip } from "@material-ui/core";
import Label from "@ui/components/Label";
import { Button } from "@material-ui/core";
import enums from "helpers/enums";
import {
  connectPrinter,
  disableUpdateProduct,
  getNestedObjValue,
  getRHFieldComponent,
  handleCloneRecord,
  isVendor,
  printFromConnectedDevice,
  userDetails,
} from "@ui/Utils/helper";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, IconButton } from "@mui/material";
import {
  getPaginatedData,
  getSingleRecord,
  handleLocalTagUpdate,
  initPrinterTemplates,
  updateRecord,
} from "../../../api";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import RHAsyncAutoComplete from "@ui/ReactHookFormControls/RHAsyncAutoComplete";
import { useMediaQuery } from "@material-ui/core/index";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import SevereColdIcon from "@mui/icons-material/SevereCold";
import CloseIcon from "@mui/icons-material/Close";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import commonConfig from "config/commonConfig";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
    backgroundColor: "#978cff",
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: "#0C0553",
      color: "white",
    },
  },
  cancelButton: {
    marginRight: theme.spacing(2),
    color: "white",
    backgroundColor: "#fad393",
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: "orange",
      color: "white",
    },
  },
  resetButton: {
    backgroundColor: "gold",
    color: "black",
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: "#F9A825",
    },
  },
}));

const DetailContent = ({
  isEditable,
  onSubmit,
  setIsEditable,
  configuration,
  openDiamondDetails,
  openConfiguration,
  purities = [], // here its ok..
  isCloned,
  defaultExtrasPrice,
}) => {
  const formMethods = useFormContext();
  const values = formMethods.watch();
  const { setValue } = formMethods;
  const navigate = useNavigate();
  const [printemplates, setPrintemplates] = useState([]);
  const [vendorCodes, setVendorCodes] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        if (isVendor) {
          setVendorCodes(userDetails.vendorCode);
        } else {
          const res = await getSingleRecord(
            enums.models["vendor-portal"].vendorCodes
          );

          setVendorCodes(res?.availableCodes || []);
        }
      } catch (err) {
        console.log(err);
      }
    })();

    (async () => {
      const localTagTemplate = await initPrinterTemplates(setPrintemplates);
      if (localTagTemplate) {
        setValue("frontEnd.printerTemplate", localTagTemplate);
      }
    })();

    // (async () => {
    //   try {
    //     const res = await search(enums.models.products, {
    //       distinct: "color",
    //       filterColumns: { color: 1 },
    //     });

    //     setColorOptions((res || []).map((prod) => prod.color).filter((c) => c));
    //   } catch (err) {
    //     console.log(err);
    //   }
    // })();
  }, []);

  const handlePrint = async ({ printWithoutEdit = false } = {}) => {
    let connectedDevice = (await navigator.usb.getDevices()) || [];
    connectedDevice = connectedDevice.find((device) => {
      if (device.vendorId) {
        return true;
      }
    });

    const device = connectedDevice || (await connectPrinter());

    if (!device) {
      return CustomEventEmitter.emit("alert_error", "Connect Printer First");
    }

    if (printWithoutEdit) {
      const stock = (
        await getPaginatedData(enums.models["data-engine"].products, {
          criteria: [{ _id: values._id }, {}],
        })
      )?.records?.[0];

      const printCommand = eval(values.frontEnd?.printerTemplate?.tsplCommands);

      try {
        await printFromConnectedDevice(device, printCommand);

        const res = await updateRecord(enums.models["data-engine"].products, {
          code: values.code,
          printHistory: [...(values.printHistory || []), { date: new Date() }],
        });

        formMethods.reset({ ...res });

        CustomEventEmitter.emit("alert_success", "Printed Successfully");
      } catch (err) {
        console.log(err);

        CustomEventEmitter.emit(
          "alert_error",
          err?.response?.data?.message || err?.message || "Something went wrong"
        );
      }
    } else {
      setValue("frontEnd.newPrint", device);
      formMethods.handleSubmit(onSubmit)();
    }
  };

  if (openDiamondDetails) {
    return null;
  }

  if (openConfiguration) {
    return null;
  }

  if (!purities.length) return null;

  const ActionButtons = () => {
    const classes = useStyles();

    return (
      <Grid item xs={12}>
        {isEditable ? (
          <>
            <Grid item xs={12} container justify="flex-end">
              {values.code ? (
                <Button
                  color="secondary"
                  onClick={() => {
                    if (window.confirm("Discard changes?")) {
                      formMethods.reset();
                      setIsEditable(false);
                    }
                  }}
                  className={classes.cancelButton}
                >
                  Cancel
                </Button>
              ) : (
                isCloned && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      navigate(`/${enums.models.dataEntry}`, { state: {} });
                      window.location.reload(); // reload i guess here its required.. find a solution to do this without reload some day soon.. #aug_8_2024 optimized reload and unnecessary APIs...
                    }}
                    className={`${classes.button} ${classes.resetButton}`}
                  >
                    <RestartAltIcon
                      style={{ marginRight: "8px", color: "black" }}
                    />
                    Reset
                  </Button>
                )
              )}

              <Button
                variant="contained"
                className={classes.button}
                onClick={formMethods.handleSubmit(onSubmit)}
              >
                {values.code ? "Update" : "Add to Inventory"}
              </Button>
            </Grid>

            <Grid
              item
              xs={12}
              container
              justify="flex-end"
              style={{ marginTop: "16px" }}
            >
              <RHAutoComplete
                style={{ width: "100%" }}
                name={"frontEnd.printerTemplate"}
                options={printemplates || []}
                label={"Printemplate"}
                disableClearable={true}
                getOptionSelected={(opt, val) => opt?.name === val?.name}
                getOptionLabel={(option) => option?.name || ""}
                onChange={(_, v) => {
                  handleLocalTagUpdate(v);
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              justify="flex-end"
              style={{ marginTop: "16px" }}
            >
              <Button
                variant="contained"
                className={classes.button}
                onClick={handlePrint}
              >
                {values.code ? "Update & Print" : "Add to Inventory & Print"}
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsEditable(true)}
                disabled={disableUpdateProduct(values)}
                className={classes.button}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "orange" }}
                onClick={() => {
                  const currValues = formMethods.getValues();

                  if (!currValues["setting"]) {
                    delete currValues["setting"];
                  }
                  if (!currValues["screw"]) {
                    delete currValues["screw"];
                  }

                  if (currValues.diamondDetails?.length) {
                    // temporary solution, do this inside handleCloneRecord only....
                    currValues.diamondDetails = currValues.diamondDetails.map(
                      (diamond) => {
                        Object.keys(diamond || {}).map(
                          (key) => !diamond[key] && delete diamond[key]
                        );

                        return diamond;
                      }
                    );
                  }

                  handleCloneRecord(
                    navigate,
                    currValues,
                    {
                      model: `${enums.models.dataEntry}`,
                      removeFields: [
                        "_id",
                        "code",
                        "client",
                        "divaSynced",
                        "frontEnd",
                        "division",
                        "sku",
                        "image",
                        "stockStatus",
                        "printHistory",
                        "lockedFrom",
                        "packagingHistory",
                        "dateCreated",
                        "dateModified",
                        "createdBy",
                        "modifiedBy",
                      ],
                    },
                    { success: "Cloned & Ready for a new stock" }
                  );
                }}
                className={classes.button}
              >
                Clone
              </Button>
            </Grid>

            <Grid
              item
              xs={12}
              container
              justify="flex-end"
              style={{ marginTop: "16px" }}
            >
              <RHAutoComplete
                style={{ width: "100%" }}
                name={"frontEnd.printerTemplate"}
                options={printemplates || []}
                label={"Printemplate"}
                disableClearable={true}
                getOptionSelected={(opt, val) => opt?.name === val?.name}
                getOptionLabel={(option) => option?.name || ""}
                onChange={(_, v) => {
                  handleLocalTagUpdate(v);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                style={{ marginTop: "16px" }}
                variant="contained"
                color="primary"
                onClick={() => handlePrint({ printWithoutEdit: true })}
                className={classes.button}
              >
                Print Tag
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={3} style={{ padding: "24px" }}>
        <Grid item xs={12}>
          <StockLevelFields
            isEditable={isEditable}
            configuration={configuration}
            vendorCodes={vendorCodes}
            defaultExtrasPrice={defaultExtrasPrice}
          />
        </Grid>

        <ActionButtons />
      </Grid>

      {/* <Grid container spacing={2}>
        Print History
        <ul>
          {values.printHistory?.map((print) => (
            <li>Printed Date: {formatDateToRead(print.data)}</li>
          ))}
        </ul>
      </Grid> */}
    </>
  );
};

const StockLevelFields = ({
  isEditable,
  configuration,
  vendorCodes = [],
  defaultExtrasPrice,
}) => {
  const { watch } = useFormContext();
  const values = watch();
  const isMobile = useMediaQuery("(max-width:1279px)"); // measure in inspect element, top bar -> Dimensions

  const renderField = (field, fieldIndex) => {
    switch (field.name) {
      case "setting":
      case "screw":
        return field.hidden ? null : !isEditable || field.readOnly ? (
          <Grid item xs={12} sm={6} md={3} lg={3} key={fieldIndex}>
            <Label value={values[field.name] || ""} label={field.label} />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} md={3} lg={3} key={fieldIndex}>
            {" "}
            <RHAutoComplete
              name={field.name}
              label={field.label}
              options={
                field.name === "screw"
                  ? Object.values(enums.screws)
                  : Object.values(enums.goldExtrasMaster.setting)
              }
            />
          </Grid>
        );

      case "vendorCode":
        return field.hidden ? null : !isEditable || field.readOnly ? (
          <Grid item xs={12} sm={6} md={3} lg={3} key={fieldIndex}>
            <Label value={values?.vendorCode || ""} label={field.label} />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} md={3} lg={3} key={fieldIndex}>
            <RHAutoComplete
              name={field.name}
              label={field.label}
              options={vendorCodes}
              defaultValue={
                isVendor && !values.code ? vendorCodes[0] : undefined
              }
              disableClearable={isVendor}
            />
          </Grid>
        );

      case "zone":
        return field.hidden ? null : !isEditable || field.readOnly ? (
          <Grid item xs={12} sm={6} md={3} lg={3} key={fieldIndex}>
            <Label value={values?.zone || ""} label={field.label} />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} md={3} lg={3} key={fieldIndex}>
            <RHAsyncAutoComplete
              customBaseApiUrl={commonConfig.baseApi["data-engine"]}
              freeSolo
              autoSelect
              apiUrl={
                enums.models["data-engine"].products + "/distinctFieldValues"
              }
              name={field.name}
              label={field.label}
              getOptionLabel={(opt) => opt || ""}
              apiBody={{ field: "zone" }}
              dynamicAPIbody={true}
            />
          </Grid>
        );

      /* field.hidden ? null : !isEditable || field.readOnly ? (
          <Label value={values?.color || ""} label={field.label} />
        ) : (
          <RHAutoComplete
            name={field.name}
            label={field.label}
            options={colorOptions || []}
            onChange={(_, val) => {
              window.sessionStorage.setItem(
                enums.sessionStorage.recentSelectedColor,
                JSON.stringify(val)
              );
            }}
          />
        );
        */

      default:
        return (
          <Grid item xs={12} sm={6} md={3} lg={3} key={fieldIndex}>
            {getRHFieldComponent(field, { isEditable, values })}
          </Grid>
        );
    }
  };

  const extraFields = (configuration?.fields || []).filter(
    (field) =>
      originalFields.find((og) => og.name === field.name) && // oye, this is Abhi Validation XD
      (field.checked || getNestedObjValue(values, field.name)) &&
      !(field.weightField || field.priceField || field.name.match("Pieces"))
  );

  const stoneFields = (configuration?.fields || []).filter(
    (field) =>
      originalFields.find((og) => og.name === field.name) && // oye, this is Abhi Validation XD.. kuch bee
      (field.checked || getNestedObjValue(values, field.name)) &&
      (field.weightField || field.priceField || field.name.match("Pieces"))
  );

  let isEmptyPrintedField = {};

  return (
    <MainSection title="Extras">
      <Grid container spacing={3}>
        {/* Render extra fields */}
        {extraFields.map((field, fieldIndex) => renderField(field, fieldIndex))}
      </Grid>
      {/* Empty boxes to start a new row if needed */}
      {/* {extraFields.length % 4 !== 0
          ? Array.from({ length: 4 - (extraFields.length % 4) }).map(
              (_, index) => <Grid item xs={3} key={index}></Grid>
            )
          : null} */}

      <Grid container spacing={3}>
        {/* Iterate over stoneFields */}
        {stoneFields.map((stoneField, stoneFieldIndex) => {
          const baseName = stoneField.name.replace(/(Weight|Pieces|Rate)$/, ""); // Extract base name

          // Check for corresponding fields with the same base name
          const hasWeightField = stoneFields.some(
            (field) => field.name === `${baseName}Weight`
          );
          const hasPiecesField = stoneFields.some(
            (field) => field.name === `${baseName}Pieces`
          );
          const hasRateField = stoneFields.some(
            (field) => field.name === `${baseName}Rate`
          );
          // const hasPriceField = stoneFields.some(
          //   (field) => hasRateField && field.includeForTotalExtrasPrice
          // );

          isEmptyPrintedField[baseName] = isEmptyPrintedField[baseName] || 0;
          isEmptyPrintedField[baseName] += 1;

          return (
            <React.Fragment key={stoneFieldIndex}>
              {/* Add empty grid boxes before StoneField if necessary */}
              {!hasWeightField && isEmptyPrintedField[baseName] === 1 ? (
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Label label={stoneField.label.replace("Pieces", "Weight")} />
                </Grid>
              ) : stoneField.name.includes("Weight") ? (
                <Grid item xs={12} sm={6} md={3} lg={3} key={stoneFieldIndex}>
                  {getRHFieldComponent(
                    {
                      ...stoneField,
                      style: stoneField.includeForLessWeight
                        ? { color: enums.colorTheme.primary }
                        : {},
                      labelStyle: stoneField.includeForLessWeight
                        ? { color: enums.colorTheme.primary }
                        : {},
                      valueStyle: stoneField.includeForLessWeight
                        ? { color: enums.colorTheme.primary }
                        : {},
                    },
                    { isEditable, values }
                  )}
                </Grid>
              ) : null}

              {!hasPiecesField && isEmptyPrintedField[baseName] === 1 ? (
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Label label={stoneField.label.replace("Weight", "Pieces")} />
                </Grid>
              ) : stoneField.name.includes("Pieces") ? (
                <Grid item xs={12} sm={6} md={3} lg={3} key={stoneFieldIndex}>
                  {getRHFieldComponent(stoneField, { isEditable, values })}
                </Grid>
              ) : null}

              {!hasRateField &&
              ((hasWeightField &&
                hasPiecesField &&
                isEmptyPrintedField[baseName] === 2) ||
                (hasWeightField &&
                  !hasPiecesField &&
                  isEmptyPrintedField[baseName] === 1) ||
                (hasPiecesField &&
                  !hasWeightField &&
                  isEmptyPrintedField[baseName] === 1)) ? (
                <>
                  {isMobile ? null : (
                    <>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={3}></Grid>
                    </>
                  )}
                </>
              ) : isVendor ? null : (
                <StoneField
                  masterRate={defaultExtrasPrice[stoneField.name]}
                  field={stoneField}
                  isEditable={isEditable}
                  fieldIndex={stoneFieldIndex}
                />
              )}
            </React.Fragment>
          );
        })}
      </Grid>
    </MainSection>
  );
};

// @Override ;)
const StoneField = ({
  masterRate,
  isEditable,
  field,
  // valueStyle,
  fieldIndex,
}) => {
  const [isOverridingIt, setIsOverriding] = useState(false);
  const priceFieldLabel =
    field.label +
    " " +
    (field.pricePerWeight
      ? "(perWeight)"
      : field.pricePerPiece
      ? "(perPiece)"
      : "");
  const { watch, setValue } = useFormContext(); // getValues, watch // later bee
  // const [originalPriceFieldValue, setOriginalPriceFieldValue] = useState();
  const values = watch();
  const overridenFlag =
    values[field.name] == "" || !isNaN(parseFloat(values[field.name]));

  // useEffect(()=> {
  //   if(oneFlag())
  //   setOriginalPriceFieldValue(value)
  // }, [field.name,])

  useEffect(() => {
    if (!isEditable) {
      setIsOverriding();
    }
  }, [isEditable]);

  const handleEditClick = () => {
    if (
      overridenFlag || // if already overriden
      window.confirm(
        `Are you sure to Override? \n{ ${field.label} for "${
          values.sku || "this_stock"
        }" will not follow master Metal Rate }`
      )
    ) {
      setIsOverriding(field.name);
      setValue(field.name, "");
    }
  };

  const handleSaveClick = () => {
    setIsOverriding();
  };

  if (field.priceField) {
    return (
      <Fragment key={fieldIndex}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {isOverridingIt ? (
              // Edit mode: Display TextField

              <Box display="flex" alignItems="center" flexGrow={1}>
                <RHTextField
                  name={field.name}
                  label={priceFieldLabel}
                  type={field?.dataType}
                  inputProps={
                    field.weightField || field.priceField ? { min: "0" } : {}
                  }
                  onChange={field.onChange || (() => {})}
                  multiline={field.multiline}
                  rows={field.rows}
                  placeholder={field.placeholder}
                  style={field.style}
                  onKeyDown={field.onKeyDown}
                  autoComplete={field.autoComplete}
                  fullWidth
                  onBlur={() => {
                    setIsOverriding(false);
                  }} // Add this to handle onBlur event
                />
                <IconButton
                  size="small"
                  onClick={handleSaveClick}
                  aria-label="save"
                  sx={{ ml: 1 }}
                >
                  <Tooltip title="overriden">
                    <AcUnitIcon
                      style={{ color: enums.colorTheme.teritiary }}
                      fontSize="small"
                    />
                  </Tooltip>
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    setValue(field.name, null);
                    setIsOverriding();
                  }}
                  aria-label="save"
                  sx={{ ml: 1 }}
                >
                  <Tooltip title="@reset">
                    <CloseIcon fontSize="small" />
                  </Tooltip>
                </IconButton>
              </Box>
            ) : (
              // Read-only mode: Display Label
              <Box display="flex" alignItems="center" flexGrow={1}>
                <Box flexGrow={1}>
                  {/* This Box ensures the Label takes the full available space */}
                  <Label
                    value={
                      overridenFlag ? values[field.name] || "0" : masterRate
                    }
                    label={priceFieldLabel}
                    valueStyle={{
                      color: overridenFlag ? enums.colorTheme.teritiary : "",
                    }}
                    labelStyle={{
                      color: overridenFlag ? enums.colorTheme.teritiary : "",
                    }}
                  />
                </Box>

                {/* Edit Icon Button aligned to the right */}
                {isEditable && (
                  <>
                    <IconButton
                      size="small"
                      onClick={handleEditClick}
                      aria-label="edit"
                      sx={{ ml: 1 }}
                    >
                      <Tooltip
                        title={overridenFlag ? "overridden" : "@override it"}
                      >
                        {overridenFlag ? (
                          <AcUnitIcon
                            fontSize="small"
                            style={{ color: enums.colorTheme.teritiary }}
                          />
                        ) : (
                          <SevereColdIcon fontSize="small" />
                        )}
                      </Tooltip>
                    </IconButton>

                    {overridenFlag && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          setValue(field.name, null);
                          setIsOverriding();
                        }}
                        aria-label="save"
                        sx={{ ml: 1 }}
                      >
                        <Tooltip title="@reset">
                          <CloseIcon fontSize="small" />
                        </Tooltip>
                      </IconButton>
                    )}
                  </>
                )}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          {/* {field.includeForTotalExtrasPrice ? ( */}
          <Label
            valueStyle={{
              color: field.includeForTotalExtrasPrice
                ? enums.colorTheme.fouraryLight
                : "",
            }}
            labelStyle={{
              color: field.includeForTotalExtrasPrice
                ? enums.colorTheme.fouraryLight
                : "",
            }}
            value={
              // Number(renderedValue || 0) *
              (!overridenFlag ? masterRate || "0" : values[field.name] || "0") *
                (field.pricePerWeight
                  ? Number(values[field.name.replace("Rate", "Weight")])
                  : Number(values[field.name.replace("Rate", "Pieces")])) || 0
            }
            label={field.label.replace("Rate", "Price")}
          />
        </Grid>
      </Fragment>
    );
  }

  return null;
};

export default DetailContent;

// Add this as Global Master Database and read from API
export const originalFields = [
  {
    fieldType: enums.fieldTypes.dropdown,
    label: enums.stockLevelField.vendorCode,
    name: "vendorCode",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    label: enums.stockLevelField.zone,
    name: "zone",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.qty,
    name: "qty",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.size,
    name: "size",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.actualGrossWeight,
    name: "actualGrossWeight",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    label: enums.stockLevelField.specificEngraving,
    name: "specificEngraving",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.dropdown,
    label: enums.stockLevelField.setting,
    name: "setting",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.dropdown,
    label: enums.stockLevelField.screw,
    name: "screw",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    label: enums.stockLevelField.customField,
    name: "customField",
    checked: false,
  },

  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.customDiamondSize,
    name: "customDiamondSize",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.customDiamondWeight,
    name: "customDiamondWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.customDiamondPieces,
    name: "customDiamondPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.customDiamondRate,
    name: "customDiamondRate",
    checked: false,
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
  },

  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.stoneWeight,
    name: "stoneWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.stonePieces,
    name: "stonePieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.stoneRate,
    name: "stoneRate",
    priceField: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
    readOnly: true,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.colorStoneWeight,
    name: "colorStoneWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.colorStonePieces,
    name: "colorStonePieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.colorStoneRate,
    name: "colorStoneRate",
    priceField: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
    readOnly: true,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.chainWeight,
    name: "chainWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.chainPieces,
    name: "chainPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.chainRate,
    name: "chainRate",
    checked: false,
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.motiWeight,
    name: "motiWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.motiPieces,
    name: "motiPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.motiRate,
    name: "motiRate",
    checked: false,
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.moti2Weight,
    name: "moti2Weight",
    checked: false,
    weightField: true,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.moti2Pieces,
    name: "moti2Pieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.moti2Rate,
    name: "moti2Rate",
    checked: false,
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.moti3Weight,
    name: "moti3Weight",
    checked: false,
    weightField: true,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.moti3Pieces,
    name: "moti3Pieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.moti3Rate,
    name: "moti3Rate",
    checked: false,
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.kundunWeight,
    name: "kundunWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.kundunPieces,
    name: "kundunPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.kundunRate,
    name: "kundunRate",
    checked: false,
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
  },

  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.meenaWeight,
    name: "meenaWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.meenaPieces,
    name: "meenaPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.meenaRate,
    name: "meenaRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinWeight,
    name: "pinWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinPieces,
    name: "pinPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinRate,
    name: "pinRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.beltWeight,
    name: "beltWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.beltPieces,
    name: "beltPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.beltRate,
    name: "beltRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.moissaniteWeight,
    name: "moissaniteWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.moissanitePieces,
    name: "moissanitePieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.moissaniteRate,
    name: "moissaniteRate",
    checked: false,
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
  },

  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.motherOfPearlWeight,
    name: "motherOfPearlWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.motherOfPearlPieces,
    name: "motherOfPearlPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.motherOfPearlRate,
    name: "motherOfPearlRate",
    checked: false,
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
  },

  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.dialWeight,
    name: "dialWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.dialPieces,
    name: "dialPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.dialRate,
    name: "dialRate",
    checked: false,
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
  },

  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.woodWeight,
    name: "woodWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.woodPieces,
    name: "woodPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.woodRate,
    name: "woodRate",
    checked: false,
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
  },

  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.fancyWeight,
    name: "fancyWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.fancyPieces,
    name: "fancyPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.fancyRate,
    name: "fancyRate",
    checked: false,
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
  },

  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.onyxWeight,
    name: "onyxWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.onyxPieces,
    name: "onyxPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.onyxRate,
    name: "onyxRate",
    checked: false,
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blueCsWeight,
    name: "blueCsWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blueCsPieces,
    name: "blueCsPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blueCsRate,
    name: "blueCsRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blueKCsWeight,
    name: "blueKCsWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blueKCsPieces,
    name: "blueKCsPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blueKCsRate,
    name: "blueKCsRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenCsWeight,
    name: "greenCsWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenCsPieces,
    name: "greenCsPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenCsRate,
    name: "greenCsRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkCsWeight,
    name: "pinkCsWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkCsPieces,
    name: "pinkCsPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkCsRate,
    name: "pinkCsRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.flowerWeight,
    name: "flowerWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.flowerPieces,
    name: "flowerPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.flowerRate,
    name: "flowerRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },

  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pearlWeight,
    name: "pearlWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pearlPieces,
    name: "pearlPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pearlRate,
    name: "pearlRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blueLWeight,
    name: "blueLWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blueLPieces,
    name: "blueLPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blueLRate,
    name: "blueLRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blue2Weight,
    name: "blue2Weight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blue2Pieces,
    name: "blue2Pieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blue2Rate,
    name: "blue2Rate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blueTWeight,
    name: "blueTWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blueTPieces,
    name: "blueTPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blueTRate,
    name: "blueTRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.rubyWeight,
    name: "rubyWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.rubyPieces,
    name: "rubyPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.rubyRate,
    name: "rubyRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkKWeight,
    name: "pinkKWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkKPieces,
    name: "pinkKPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkKRate,
    name: "pinkKRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkLWeight,
    name: "pinkLWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkLPieces,
    name: "pinkLPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkLRate,
    name: "pinkLRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pink2Weight,
    name: "pink2Weight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pink2Pieces,
    name: "pink2Pieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pink2Rate,
    name: "pink2Rate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkTWeight,
    name: "pinkTWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkTPieces,
    name: "pinkTPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkTRate,
    name: "pinkTRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenKWeight,
    name: "greenKWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenKPieces,
    name: "greenKPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenKRate,
    name: "greenKRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.green2Weight,
    name: "green2Weight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.green2Pieces,
    name: "green2Pieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.green2Rate,
    name: "green2Rate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenLWeight,
    name: "greenLWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenLPieces,
    name: "greenLPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenLRate,
    name: "greenLRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenTWeight,
    name: "greenTWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenTPieces,
    name: "greenTPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenTRate,
    name: "greenTRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.redKWeight,
    name: "redKWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.redKPieces,
    name: "redKPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.redKRate,
    name: "redKRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.redCsWeight,
    name: "redCsWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.redCsPieces,
    name: "redCsPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.redCsRate,
    name: "redCsRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.redLWeight,
    name: "redLWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.redLPieces,
    name: "redLPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.redLRate,
    name: "redLRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.redTWeight,
    name: "redTWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.redTPieces,
    name: "redTPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.redTRate,
    name: "redTRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.yellowKWeight,
    name: "yellowKWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.yellowKPieces,
    name: "yellowKPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.yellowKRate,
    name: "yellowKRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.yellowCsWeight,
    name: "yellowCsWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.yellowCsPieces,
    name: "yellowCsPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.yellowCsRate,
    name: "yellowCsRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.yellowLWeight,
    name: "yellowLWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.yellowLPieces,
    name: "yellowLPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.yellowLRate,
    name: "yellowLRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.yellowTWeight,
    name: "yellowTWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.yellowTPieces,
    name: "yellowTPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.yellowTRate,
    name: "yellowTRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.navratnaStoneWeight,
    name: "navratnaStoneWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.navratnaStonePieces,
    name: "navratnaStonePieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.navratnaStoneRate,
    name: "navratnaStoneRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.latkanWeight,
    name: "latkanWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.latkanPieces,
    name: "latkanPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.latkanRate,
    name: "latkanRate",
    priceField: true,
    readOnly: true,

    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.emeraldWeight,
    name: "emeraldWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.emeraldPieces,
    name: "emeraldPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.emeraldRate,
    name: "emeraldRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.coralWeight,
    name: "coralWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.coralPieces,
    name: "coralPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.coralRate,
    name: "coralRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.hessoniteGametWeight,
    name: "hessoniteGametWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.hessoniteGametPieces,
    name: "hessoniteGametPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.hessoniteGametRate,
    name: "hessoniteGametRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blueSapphireWeight,
    name: "blueSapphireWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blueSapphirePieces,
    name: "blueSapphirePieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blueSapphireRate,
    name: "blueSapphireRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.catsEyeWeight,
    name: "catsEyeWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.catsEyePieces,
    name: "catsEyePieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.catsEyeRate,
    name: "catsEyeRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.yellowSapphireWeight,
    name: "yellowSapphireWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.yellowSapphirePieces,
    name: "yellowSapphirePieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.yellowSapphireRate,
    name: "yellowSapphireRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },

  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blackBeadsWeight,
    name: "blackBeadsWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blackBeadsPieces,
    name: "blackBeadsPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.blackBeadsRate,
    name: "blackBeadsRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },

  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.palchaWeight,
    name: "palchaWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.palchaPieces,
    name: "palchaPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.palchaRate,
    name: "palchaRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },

  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.rudrakshaWeight,
    name: "rudrakshaWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.rudrakshaPieces,
    name: "rudrakshaPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.rudrakshaRate,
    name: "rudrakshaRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },

  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.amethystSquareWeight,
    name: "amethystSquareWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.amethystSquarePieces,
    name: "amethystSquarePieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.amethystSquareRate,
    name: "amethystSquareRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.chalcedonyWeight,
    name: "chalcedonyWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.chalcedonyPieces,
    name: "chalcedonyPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.chalcedonyRate,
    name: "chalcedonyRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.garnetMarquiseWeight,
    name: "garnetMarquiseWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.garnetMarquisePieces,
    name: "garnetMarquisePieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.garnetMarquiseRate,
    name: "garnetMarquiseRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkBeadsWeight,
    name: "pinkBeadsWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkBeadsPieces,
    name: "pinkBeadsPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkBeadsRate,
    name: "pinkBeadsRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.emeraldBeadsWeight,
    name: "emeraldBeadsWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.emeraldBeadsPieces,
    name: "emeraldBeadsPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.emeraldBeadsRate,
    name: "emeraldBeadsRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.rubyBeadsWeight,
    name: "rubyBeadsWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.rubyBeadsPieces,
    name: "rubyBeadsPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.rubyBeadsRate,
    name: "rubyBeadsRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenBeadsWeight,
    name: "greenBeadsWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenBeadsPieces,
    name: "greenBeadsPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenBeadsRate,
    name: "greenBeadsRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenEmeraldWeight,
    name: "greenEmeraldWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenEmeraldPieces,
    name: "greenEmeraldPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.greenEmeraldRate,
    name: "greenEmeraldRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkPearWeight,
    name: "pinkPearWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkPearPieces,
    name: "pinkPearPieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.pinkPearRate,
    name: "pinkPearRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.czSolitaireWeight,
    name: "czSolitaireWeight",
    weightField: true,
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.czSolitairePieces,
    name: "czSolitairePieces",
    checked: false,
  },
  {
    fieldType: enums.fieldTypes.textField,
    dataType: enums.dataTypes.number,
    label: enums.stockLevelField.czSolitaireRate,
    name: "czSolitaireRate",
    priceField: true,
    readOnly: true,
    pricePerPiece: false,
    pricePerWeight: false,
    checked: false,
  },

  // IF YOU ADD NEW WEIGHT & PRICE FIELDS, give it for lessWeight & totalExtrasPrice useEffect calculation dependancy
];

// rBin

// {
// getOptionLabel: (option) => option?.name || "",
// freeSolo: true,
// autoSelect: true,
// setOriginalOptions: setOriginalOptions,
// onChange: (_, newValue) => {
//   let value = {};
//   const existing = originalOptions.find(
//     (opt) =>
//       opt?.name?.toString().toLowerCase() ===
//       newValue?.toString().toLowerCase()
//   );
//   if (existing) {
//     value = existing;
//   } else if (typeof newValue === "string") {
//     value = {
//       name: newValue,
//     };
//   } else if (newValue && newValue.inputValue) {
//     // Create a new value from the user input
//     value = {
//       name: newValue.inputValue,
//     };
//   } else {
//     value = newValue;
//   }
//   setValue("collectionLine", value);
// },
// selectOnFocus: true,
// clearOnBlur: true,
// filterOptions: (options, params) => {
//   const filtered = filter(options, params);
//   const { inputValue } = params;
//   // Suggest the creation of a new value
//   const isExisting = options.some((option) => inputValue === option.name);
//   if (inputValue !== "" && !isExisting) {
//     filtered.push({
//       inputValue,
//       name: `Add "${inputValue}"`,
//     });
//   }
//   return filtered;
// },
// apiUrl: model + "/search",
// apiBody: {
//   filterColumns: {
//     name: 1,
//   },
// },
// apiMethod: "post",
// },
