import commonEnums from "@ui/Utils/commonEnums";
import React, { useState } from "react";

const ReadMore = ({
  content = "-",
  limit = 101,
  alertOnReadMore = false,
  readMoreStyle = {},
  contentStyle = {},
  style,
  toggleReadMore = true,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  if (!content) {
    return null;
  }

  return (
    <div style={style}>
      {content.length > limit && !expanded ? (
        <>
          <span
            style={{
              ...contentStyle,
              // display: "inline-block",
              // whiteSpace: "nowrap", // Prevent line breaks
              // overflow: "hidden", // Hide overflow if the content is too long
              // textOverflow: "ellipsis",
              wordBreak: "break-all",
            }}
          >
            {content.slice(0, limit)}...
          </span>
          <button
            onClick={() => {
              if (alertOnReadMore) {
                window.alert(content);
              } else if (toggleReadMore) {
                toggleExpand();
              }
            }}
            style={{
              color: commonEnums.colorTheme.primary,
              cursor: "pointer",
              background: "none",
              border: "none",
              padding: 0,
              lineHeight: "1", // Properly reduce the line gap

              ...readMoreStyle,
            }}
          >
            Read More
          </button>
        </>
      ) : (
        <>
          <span style={{ ...contentStyle }}>{content}</span>
          <span>
            {content.length > limit && (
              <button
                onClick={toggleExpand}
                style={{
                  color: commonEnums.colorTheme.primary,
                  cursor: "pointer",
                  background: "none",
                  border: "none",
                  padding: 0,
                  lineHeight: "1", // Ensure this matches for consistency
                  ...readMoreStyle,
                }}
              >
                Read Less
              </button>
            )}
          </span>
        </>
      )}
    </div>
  );
};

export default ReadMore;
