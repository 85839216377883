import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getByCode, updateRecordWithHook } from "../../api";
import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";

// const validationSchema = yup.object().shape({
//   product: yup.string().required("Required"),
//   item: yup
//     .object()
//     .shape({
//       name: yup.string().required("Required"),
//     })
//     .required("Required"),
//   size: yup.string().required("Required"),
//   color: yup.string().required("Required"),
//   stick: yup.string().when("item", {
//     is: (data) => data === "rings",
//     then: yup.string().required("Required").nullable(),
//     otherwise: yup.string().nullable(),
//   }),
// });

const entitySchema = {};

const ExpiryLinkDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    // resolver: yupResolver(validationSch),
  });
  const location = useLocation();
  const formData = location.state?.formData;
  const navigate = useNavigate();
  const model = enums.models["e-creatives"]?.expiryLinks;
  const params = useParams();
  const [isEditable, setIsEditable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  // const values = formMethods.watch();

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          const res = formData ? formData : await getByCode(model, id);
          formMethods.reset(res);
          setInitialValues(res);
          setIsEditable(true);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + model);
        console.log(err);
      }
    })();
  }, [params?.code, formData]);

  const onSubmit = async (values) => {
    try {
      let action = "Created",
        res;

      // const invalidItem = (values.items || []).find((item, index) => {
      //   if (
      //     item.orderStatus === enums.orderStatus.delivered &&
      //     !item.issuedStocks?.length
      //   ) {
      //     itemIndex = { index, orderStatus: item.orderStatus };
      //     return true;
      //   }

      //   if (
      //     item.orderStatus === enums.orderStatus.repair &&
      //     !item.repairStocks?.length
      //   ) {
      //     itemIndex = { index, orderStatus: item.orderStatus };
      //     return true;
      //   }

      //   if (
      //     item.orderStatus === enums.orderStatus.returned &&
      //     !item.returnedStocks?.length
      //   ) {
      //     itemIndex = { index, orderStatus: item.orderStatus };
      //     return true;
      //   }
      // });

      // if (invalidItem) {
      //   return CustomEventEmitter.emit(
      //     "alert_error",
      //     `Fill ${itemIndex.orderStatus} Stocks for Item: ${
      //       itemIndex.index + 1
      //     } to Continue...`
      //   );
      // }

      if (values._id) {
        res = await updateRecordWithHook(model, values);
        action = "Updated";
        CustomEventEmitter.emit("alert_success", action + " Successfully");
        setIsEditable(false);

        navigate("/" + enums.models["e-creatives"].linkItOrders);
        // formMethods.reset(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        loading={loading}
        setIsEditable={setIsEditable}
        initialValues={initialValues}
      />
    </FormProvider>
  );
};

export default ExpiryLinkDetail;
