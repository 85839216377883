import React, { useState } from "react";
import enums from "helpers/enums";
import { downloadProductsTemplate, handleProductsImport } from "./excelImport";
import { Grid, Box } from "@mui/material";
import DocUpload from "@ui/Controls/Upload/DocUpload";
import Checkbox from "@mui/material/Checkbox";
import { useEffect } from "react";
import "./errorResponseUI.css";
import { getSingleRecord } from "../api";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { sheetNames } from "@ui/Utils/excelImportHelpers";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import PublishIcon from "@mui/icons-material/Publish";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import { Review } from "./ImageUpProgress";
import { isVendor } from "@ui/Utils/helper";

const BulkUpload = () => {
  const [files, setFiles] = useState([]);

  const [readingFiles, setReadingFiles] = useState(false);
  const [readingRows, setReadingRows] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [configuration, setConfiguration] = useState({});
  const [responseArray, setResponseArray] = useState([]);
  const [replaceStocks, setReplaceStocks] = useState(false);

  useEffect(() => {
    (async () => {
      let errorMessage =
        "Create a Configuration for your fields in Data Entry Settings to continue.";
      try {
        const res =
          (await getSingleRecord(enums.models["data-engine"].configuration)) ||
          {};

        if (!res?._id) {
          setErrorMessage(errorMessage);
        } else {
          setConfiguration(res);
          setErrorMessage("");
        }
      } catch (err) {
        console.log(err);
        setErrorMessage(errorMessage);
      }
    })();
  }, []);

  const handleImport = async () => {
    if (files?.[0]) {
      if (
        replaceStocks
          ? window.confirm("Are you sure to UPDATE Existing Matched SKUs?")
          : true
      ) {
        const resArray = await handleProductsImport(
          files[0],
          setReadingFiles,
          setReadingRows,
          { replaceStocks }
        );

        setResponseArray(resArray);
      } else {
        setReadingFiles(false);
      }

      setFiles([]);
    } else {
      window.alert("No File Selected");
    }

    setFiles([]);
  };

  if (errorMessage) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <b>{errorMessage}</b>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3} style={{ minHeight: "720px", padding: "24px" }}>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        style={{ marginLeft: "38px" }}
      >
        <Grid item xs={12} md={4}>
          <h3 style={{ color: "#1d9bc2" }}>Bulk Stock Upsert</h3>
          <pre style={{ fontSize: "12px" }}>
            Create {replaceStocks ? "& Update " : ""}Stocks
          </pre>
          {isVendor ? null : (
            <Box
              display="flex"
              alignItems="center"
              onClick={() => setReplaceStocks(!replaceStocks)}
              style={{ userSelect: "none", cursor: "pointer" }}
            >
              <Box ml={1}>
                <pre
                  style={{
                    fontWeight: replaceStocks ? "bold" : "",
                    fontStyle: replaceStocks ? "" : "italic",
                    marginRight: "8px",
                    whiteSpace: "pre",
                    fontFamily: "monospace",
                  }}
                >
                  {`>> UPDATE`}
                </pre>
              </Box>

              <Checkbox
                checked={replaceStocks}
                sx={{
                  color: enums.colorTheme.primary,
                  "&.Mui-checked": {
                    color: enums.colorTheme.primary,
                  },
                }}
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <div>
            <MyHoverIcons
              onClick={() => downloadProductsTemplate(configuration)}
              tooltip={"Download excel template"}
              muiIcon={
                <PublishOutlinedIcon
                  style={{
                    fontSize: "68px",
                    color: "#1d9bc2",
                    transform: "rotate(180deg)",
                  }}
                />
              }
              muiIconHover={
                <PublishIcon
                  style={{
                    fontSize: "68px",
                    color: "#1d9bc2",
                    transform: "rotate(180deg)",
                  }}
                />
              }
            />
          </div>
          <pre style={{ paddingTop: "24px", fontSize: "16px" }}>
            Download excel template
          </pre>
        </Grid>

        <Grid item xs={12} md={4}>
          <div>
            <DocUpload
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              files={files}
              setFiles={setFiles}
              handleUpload={handleImport}
              setReadingFiles={setReadingFiles}
              uploadIcon={{
                icon: PublishOutlinedIcon,
                iconOnHover: PublishIcon,
                type: "Component",
                style: { fontSize: "68px", color: "#1d9bc2" },
              }}
              tooltip={"Upload excel"}
            />
          </div>
          <pre style={{ fontSize: "16px" }}>Upload excel</pre>
        </Grid>
      </Grid>
      {readingFiles ? (
        <Grid item xs={12} container justifyContent="center">
          <pre>Processing {readingRows || 0} rows...</pre>
        </Grid>
      ) : null}

      {responseArray.length ? (
        <Grid item xs={12} style={{ padding: "64px", paddingTop: 0 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              Created: <b>{responseArray[responseArray.length - 1].created}</b>
            </span>
            <span>
              Updated: <b>{responseArray[responseArray.length - 1].updated}</b>
            </span>
            {!replaceStocks ? (
              <span>
                Skipped:{" "}
                <b>{responseArray[responseArray.length - 1].ignored}</b>
              </span>
            ) : null}
            <span>
              Failed: <b>{responseArray[responseArray.length - 1].failed}</b>
            </span>
            <span>
              Total: <b>{responseArray[responseArray.length - 1].total}</b>
            </span>
          </div>
        </Grid>
      ) : null}

      {responseArray[responseArray.length - 1]?.failed ? (
        <Grid
          item
          xs={12}
          style={{ marginBottom: "-24px", marginTop: "-24px" }}
        >
          <Review />
        </Grid>
      ) : null}

      {responseArray[responseArray.length - 1]?.failed ||
      responseArray[responseArray.length - 1]?.ignored ? (
        <Grid item xs={configuration?.diamondFields ? 6 : 12}>
          <div style={{ padding: "24px" }}>
            <TableContainer component={Paper} className="table-wrapper">
              <div className="sheet-line">
                Sheet: <b>{sheetNames.products}</b>
              </div>
              <Table className="table" aria-label="simple table">
                <TableHead>
                  <TableRow
                    style={{
                      position: "sticky",
                      top: 40,

                      zIndex: 15,
                    }}
                  >
                    <TableCell
                      style={{
                        textAlign: "center",
                        width: "68px",
                        backgroundColor: "grey",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      Row
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "grey",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      Invalid Columns
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {responseArray.map((res) =>
                    Object.keys(res.invalidData?.gold || {}).map(
                      (goldenRowNumber, i) => {
                        return res.invalidData.gold[goldenRowNumber].columns
                          .length ? (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                width: "68px",
                                textAlign: "center",
                                color: "grey",
                                fontWeight: "bold",
                              }}
                            >
                              {goldenRowNumber}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ color: "grey", fontWeight: "bold" }}
                            >
                              {(
                                res.invalidData.gold[goldenRowNumber]
                                  ?.columns || []
                              ).join(", ")}
                            </TableCell>
                          </TableRow>
                        ) : null;
                      }
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      ) : null}

      {configuration?.diamondFields &&
      responseArray[responseArray.length - 1]?.failed ? (
        <Grid item xs={6}>
          <div style={{ padding: "24px" }}>
            <TableContainer component={Paper} className="table-wrapper">
              <div className="sheet-line">
                Sheet: <b>{sheetNames.diamondDetails}</b>
              </div>
              <Table className="table" aria-label="simple table">
                <TableHead>
                  <TableRow
                    style={{
                      position: "sticky",
                      top: 40,

                      zIndex: 15,
                    }}
                  >
                    <TableCell
                      style={{
                        textAlign: "center",
                        width: "68px",
                        backgroundColor: "grey",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      SKU
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "grey",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      Invalid Columns
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {responseArray.map((res) =>
                    Object.keys(res.invalidData?.diamond || {}).map(
                      (diamondRowNumber, i) => {
                        return res.invalidData.diamond[diamondRowNumber].columns
                          .length ? (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                width: "68px",
                                textAlign: "center",
                                color: "grey",
                                fontWeight: "bold",
                              }}
                            >
                              {res.invalidData.diamond[diamondRowNumber].sku}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ color: "grey", fontWeight: "bold" }}
                            >
                              {(
                                res.invalidData.diamond[diamondRowNumber]
                                  .columns || []
                              ).join(", ")}
                            </TableCell>
                          </TableRow>
                        ) : null;
                      }
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default BulkUpload;
