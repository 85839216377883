import HTTP from "@ui/Utils/http";
import commonConfig from "config/commonConfig";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import enums from "helpers/enums";
import {
  base64ToBlobUrl,
  formatDateToRead,
  isVendor,
  userDetails,
} from "@ui/Utils/helper";
import _ from "lodash";
import { customOrderColor, onlineOrderColor } from "./List/List";
import { globalDateTimeFormat } from "@ui/ReactHookFormControls/index";
import generatePDF from "@ui/Utils/PDF/generate_pdf";
import commonEnums from "@ui/Utils/commonEnums";

const baseApiUrl = commonConfig.baseApi?.["e-creatives"];

export const paginateWithHook = async (
  model,
  { page, pageSize, sort, criteria, ...query } = {}
) => {
  try {
    const url = baseApiUrl + model + "/paginate?hook=true";

    const params = {
      ...query,
      pageNo: page + 1,
      perPageRecords: pageSize || 10,
      criteria: criteria || null,
      sort: (sort && JSON.stringify(sort)) || null,
    };

    const res = await HTTP.post(url, params);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const createRecordWithHook = async (model, data, additionalHeaders) => {
  try {
    const res = await HTTP.post(
      baseApiUrl + model + "?hook=true",
      data,
      additionalHeaders
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateRecord = async (model, data) => {
  try {
    const res = await HTTP.put(baseApiUrl + model + "/" + data?.code, data);

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateRecordWithHook = async (model, data, additionalHeaders) => {
  try {
    const res = await HTTP.put(
      baseApiUrl + model + "/" + data?.code + "?hook=true&webHook=true",
      data,
      additionalHeaders
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const searchWithHook = async (
  model,
  criteria = {},
  additionalHeaders
) => {
  try {
    const url = baseApiUrl + model + "/search?hook=true";

    const res = await HTTP.post(url, criteria, additionalHeaders);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getByCode = async (model, code) => {
  try {
    const url = baseApiUrl + model + "/" + code;

    const res = await HTTP.get(url);
    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const handleReceived = async (row, received) => {
  try {
    row.items = (row.items || []).map((item) => {
      if (
        received &&
        item.orderStatus !== enums["vendor-portal"].orderStatus.canceled &&
        item.orderStatus !== enums["vendor-portal"].orderStatus.delivered
      ) {
        item.orderStatus = enums["vendor-portal"].orderStatus.delivered;
        item.dateDelivered = Date.now();
      }

      return item;
    });

    await updateRecord(enums.models["e-creatives"].customOrders, {
      code: row.code,
      items: row.items,
      received: received,
      receivedBy: {
        _id: userDetails._id,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        role: userDetails.role,
        phone: userDetails.phone,
      },
      dateReceived: new Date(),
    });
    CustomEventEmitter.emit("alert_success", "Marked as Received");
  } catch (err) {
    console.log(err);
    CustomEventEmitter.emit("alert_error", "Something went wrong!");
  }
};

export const getCustomOrderPdfReport = async ({ data }) => {
  try {
    const res = await HTTP.post(
      commonConfig.microservices.pdfReport + "e-creatives/pdf/base64",
      {
        context: {
          customData: data,
        },
        templateName:
          "customOrders" +
          "/" +
          commonConfig.pdfVersion["e-creatives"].customOrders["Template-1"],
        pdfOptions: await pdfOptions(data),
      }
    );

    return res?.data?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const pdfOptions = async (contextData = {}) => {
  const clientInfo = userDetails.client || {};

  // let logo = "";

  // if (clientInfo?.logo?.location) {
  //   logo = `<img style="height:33px;width:80px;" src="${clientInfo?.logo?.location}" alt="" />`; //padding:10px 26px;
  // } else {
  //   logo = `<h3>${clientInfo.name}</h3>`;
  // }

  let topMargin = 5 + "cm";

  return {
    margin: {
      top: topMargin,
      right: "1.5cm",
      bottom: "0px", // to show footer.. increase this
      left: "0cm",
    },
    templateType: "ejs",
    headerTemplate: `
 <div style="-webkit-print-color-adjust: exact; font-size:12px;background-color: ${
   contextData.orderType === enums.customOrderType.online
     ? onlineOrderColor
     : customOrderColor
 }; display: flex; height: 30%; width: 90%; font-size: 10px; margin: 0 auto; padding: 10px; align-items: center; padding: 24px">
  <div style="flex: 1; width: 30%; padding-left: 15px;">
  <div style="word-break: break-all;">
  ${
    contextData.partyOrderNumber
      ? `#Party { priority: ${contextData.orderPriority} }<br><span style="color: grey; font-weight: bold;">${contextData.partyOrderNumber}</span>`
      : "&nbsp;"
  }
  </div>
  </div>
  <div style="flex: 1; text-align: center;">
    <div style="font-style: italic;">${contextData.orderType}</div>
    <div style="color: grey; font-weight: bold; margin-top: 5px;">${
      clientInfo.name
    }</div>
  </div>
  <div style="flex: 1; display: flex; flex-direction: column; justify-content: center; align-items: flex-end; font-weight: bold;">
    <div>${contextData.orderId}</div>
    <div style="color: grey;">${contextData.dateCreated}</div>
  </div>
</div>
    `,
    //   footerTemplate: `<div style="color: lightgray;  font-size: 10px; margin-top: 15px; width: 100%; margin:0  36px;">
    // <hr>
    //  <div style='display:flex; justify-content: space-between;'> <div><span style='text-align:left;font-size: 7px;'>
    //  ${
    //    clientInfo && clientInfo.address
    //      ? (clientInfo.address.street ? clientInfo.address.street + ", " : "") +
    //        (clientInfo.address.city ? clientInfo.address.city + ", " : "") +
    //        (clientInfo.address.state ? clientInfo.address.state + ", " : "") +
    //        (clientInfo.address.country && clientInfo.address.country.name
    //          ? clientInfo.address.country.name
    //          : "")
    //      : ""
    //  }
    //  </span></div>
    //  <div style="font-size: 7px;">Page <span class="pageNumber" style="font-size: 7px;"></span>
    //   of <span class="totalPages" style="font-size: 7px;"></span></div></div>
    // </div>`,
  };
};

export const downloadPdf = async (values) => {
  try {
    const customValues = _.cloneDeep(values);

    customValues.items = (customValues.items || [])
      .filter((item) =>
        isVendor
          ? (userDetails.vendorCode || []).includes(item.vendorCode)
          : true
      )
      .map((item) => {
        item.dueDate = item.dueDate ? formatDateToRead(item.dueDate) : "";
        item.dateDelivered = item.dateDelivered
          ? formatDateToRead(item.dateDelivered, globalDateTimeFormat.read)
          : "";
        item.grossWeight = Number(item.grossWeight?.toFixed(3));

        // item.attachments = (item.attachments || []).map((attachment) => {
        //   if (attachment.signedUrl)
        //     attachment.signedUrl = encodeURI(attachment.signedUrl);

        //   return attachment;
        // });

        return item;
      });

    customValues.dateCreated = formatDateToRead(
      customValues.dateCreated,
      globalDateTimeFormat.read
    );

    customValues.partyOrderNumber = isVendor
      ? ""
      : customValues.partyOrderNumber || " ";

    const res = await getCustomOrderPdfReport({
      data: customValues,
    });

    const handlePrint = (printWindow, pdfUrl) => {
      printWindow.onload = () => {
        printWindow.print();
        // Clean up the URL object to avoid memory leaks
        URL.revokeObjectURL(pdfUrl);
      };
    };

    const pdfUrl = base64ToBlobUrl(res);
    let printWindow = window.open(pdfUrl, "_blank", "width=100,height=100");

    if (printWindow) {
      // sometimes null case handled
      handlePrint(printWindow, pdfUrl);
    } else {
      handlePrint(window.open(pdfUrl, "_blank"), pdfUrl);
    }
  } catch (err) {
    console.log(err);
    CustomEventEmitter.emit("alert_error", "Something went wrong. Try Again");
  }
};

const handlePrint = (pdfBytes) => {
  try {
    // Create a Blob from the PDF bytes and generate an Object URL
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open the PDF in a new window (make sure this is triggered by user interaction like a button click)
    const printWindow = window.open(pdfUrl, "_blank", "width=800,height=600");

    if (printWindow) {
      // Listen for the window to load before triggering the print function
      printWindow.onload = () => {
        // Once the window has loaded, print the PDF
        printWindow.print();
        // Clean up the URL object to avoid memory leaks
        URL.revokeObjectURL(pdfUrl);
      };
    } else {
      // Handle if the new window could not be opened
      console.error("Failed to open print window. Ensure popups are allowed.");
    }
  } catch (error) {
    console.error("Error while handling PDF print:", error);
  }
};

// const res = await generatePDF(
//   {
//     data: customValues,
//     title: customValues.orderType,
//     appModule: "e-creatives",
//     model: "customOrders",
//     selectedTemplate: "Template-1",
//   },
//   customValues.orderId,
//   true
// );
