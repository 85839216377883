import React, { useEffect, useState } from "react";
import {
  FormProvider,
  useForm,
  useFormState,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getByCode, getSingleRecord } from "@ui/ComponentUtils/blueprintAPIs";
import enums from "helpers/enums";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@material-ui/core";
import MyKeyboardDatePicker from "@ui/MuiComponents/DatePicker/MyKeyboardDatePicker";
import { Autocomplete } from "@material-ui/lab";
import commonConfig from "config/commonConfig";
import ProductsView, {
  displayMeltingAsPerClient,
} from "./components/ProductsView";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import ElevatedPaper from "@ui/ComponentUtils/Visuals/ElevatedPaper";
import {
  formatDateToRead,
  isAdmin,
  isSuperAdmin,
  isVendor,
} from "@ui/Utils/helper";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Stack from "@mui/material/Stack";
import "./DetailsStyles.css";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Label from "@ui/components/Label";
import {
  createRecordWithHook,
  updateRecordWithHook,
  isVendorCodeEditable,
} from "../api";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import generatePDF from "@ui/Utils/PDF/generate_pdf";
// import DetailsHeader from "./components/DetailsHeader";
// const validationSchema = yup.object().shape({
//   product: yup.string().required("Required"),
//   item: yup
//     .object()
//     .shape({
//       name: yup.string().required("Required"),
//     })
//     .required("Required"),
//   size: yup.string().required("Required"),
//   color: yup.string().required("Required"),
//   stick: yup.string().when("item", {
//     is: (data) => data === "rings",
//     then: yup.string().required("Required").nullable(),
//     otherwise: yup.string().nullable(),
//   }),
// });

const buttonStyle = {
  backgroundColor: commonConfig.themeColors.lightGreen,
  fontWeight: 600,
  borderRadius: 15,
  opacity: 0.8,
  height: "50px",
  marginLeft: "16px",
};

const entitySchema = {
  products: [],
};

export const getProductViewAccess = (product) => {
  return (
    (isVendor &&
      product.orderStatus !== enums["vendor-portal"]?.orderStatus?.pending) ||
    isAdmin ||
    isSuperAdmin
  );
};
export const orderedStatusArray = [
  enums["vendor-portal"]?.orderStatus?.pending,
  enums["vendor-portal"]?.orderStatus?.assigned,
  enums["vendor-portal"]?.orderStatus?.accepted,
  enums["vendor-portal"]?.orderStatus?.inProgress,
  enums["vendor-portal"]?.orderStatus?.delivered,
  enums["vendor-portal"]?.orderStatus?.resent,
  enums["vendor-portal"]?.orderStatus?.canceled,
];

const QuotationDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    // resolver: yupResolver(validationSch),
  });
  const navigate = useNavigate();

  const model = enums.models["vendor-portal"]?.quotations;
  const [selectedProducts, setSelectedProducts] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [selectedDueDate, setSelectedDueDate] = useState();
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(false);

  const params = useParams();

  const { control, getValues, setValue } = formMethods;
  const values = getValues();
  const [bulkUpdateStatus, setBulkUpdateStatus] = useState("");
  const [vendorCodes, setVendorCodes] = useState([]);

  const { fields } = useFieldArray({
    control,
    name: `products`,
  });

  const filteredProducts = values.products.map((product) =>
    getProductViewAccess(product) ? product : {}
  );

  const isAnySelected =
    selectedProducts && Object.values(selectedProducts).find((prod) => prod);

  // const auth = useAuth();
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setVendorCodes(
          (
            (await getSingleRecord(
              enums.models["vendor-portal"].vendorCodes
            )) || {}
          ).availableCodes || []
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    setSelectedDueDate(
      filteredProducts?.find((prod) => prod?.dueDate)?.dueDate || new Date()
    );
  }, [values.products]);

  useEffect(() => {
    let currentStatusIndex = 0,
      status = "";

    Object.keys(selectedProducts).map((index) => {
      orderedStatusArray.map((stat, statIndex) => {
        if (
          selectedProducts[index] &&
          stat === values.products[index].orderStatus
        ) {
          if (statIndex > currentStatusIndex) {
            // To set currentStatusIndex to highest status index that is present in selectedProducts array to have
            // bulk update status dropdown to have next status prefilled
            if (statIndex === orderedStatusArray.length - 1) {
              currentStatusIndex = 0;
            } else {
              currentStatusIndex = statIndex;
            }
          }
        }
      });
    });
    status = orderedStatusArray[currentStatusIndex + 1];
    setBulkUpdateStatus(status || "");
  }, [selectedProducts]);

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          const res = await getByCode(model, id);

          formMethods.reset(res);
          setInitialValues(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + model);
        console.log(err);
      }
    })();
  }, [params?.code]);

  const onSubmit = async (values, metaData) => {
    try {
      let res = {};

      if (!values._id) {
        res = await createRecordWithHook(model, values);
        navigate(`/${model}/${res?.code}`);
      } else {
        res = await updateRecordWithHook(model, values);
        navigate(`/${model}/${res?.code}`);
      }

      CustomEventEmitter.emit("alert_success", "Updated Successfully");
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  // const handlePlaceOrder = () => {
  //   const modifiedProducts = values.products || [];

  //   modifiedProducts.map((_, productIndex) => {
  //     if (selectedProducts[productIndex]) {
  //       modifiedProducts[productIndex].orderStatus = enums["vendor-portal"]?.orderStatus?.assigned;
  //       modifiedProducts[productIndex].dueDate =
  //         selectedDueDate || modifiedProducts[productIndex].dueDate;
  //       modifiedProducts[productIndex].dateModified = new Date();
  //     }
  //   });

  //   setValue("products", modifiedProducts);
  //   setSelectedProducts({});
  //   setSelectAll(false);
  //   formMethods.handleSubmit(onSubmit)();
  // };

  const downloadPdf = async () => {
    try {
      setLoading(true);
      const setting = await getSingleRecord(
        enums.models["vendor-portal"]?.settings
      );

      if (!setting?._id || !setting?.pdfTemplate) {
        return CustomEventEmitter.emit(
          "alert_error",
          "Configure PDF Settings to continue"
        );
      }

      let totalGrossWeight = 0,
        totalDiamondsWeight = 0,
        totalNetWeight = 0,
        totalQuantity = 0;

      let pdfProducts = filteredProducts?.filter((prod) => prod?.styleNo) || [];

      pdfProducts = pdfProducts.map((prod) => {
        totalGrossWeight +=
          (Number(prod.grossWeight) || 0) * (prod.quantity || 0);
        totalDiamondsWeight +=
          (prod?.totalDiamondWeight || 0) * (prod.quantity || 0);
        totalNetWeight += (Number(prod?.netWeight) || 0) * (prod.quantity || 0);
        totalQuantity += Number(prod.quantity) || 0;

        // image: encodeURI(prod?.images?.[0]?.signedUrl),
        return {
          styleNo: prod?.styleNo,
          grossWeight: (Number(prod?.grossWeight) || 0).toFixed(3),
          totalDiamondWeight: prod?.totalDiamondWeight
            ? Number(prod.totalDiamondWeight).toFixed(2)
            : undefined,
          netWeight: (Number(prod?.netWeight) || 0).toFixed(3),
          purity: setting.mapPurityToPercent
            ? displayMeltingAsPerClient(prod?.metalPurity)
            : prod?.metalPurity,
          color: prod.metalType,
          adminRemarks: prod?.adminRemarks,
          dueDate: prod?.dueDate ? formatDateToRead(prod?.dueDate) : "",
          image: prod?.images?.[0]?.signedUrl,
          quantity: prod?.quantity,

          motiWeight: prod?.motiWeight,
          kundanWeight: prod?.kundanWeight,
        };
      });

      const orderDate = values.orderDate
        ? formatDateToRead(values.orderDate)
        : "";

      // Check in e-creatives/orders pdf as well.
      await generatePDF(
        {
          data: {
            vendorCode: values.vendorCode,
            remarks: values.remarks,
            orderId: values.quotationNo,
            orderDate: orderDate,
            products: pdfProducts,

            totalGrossWeight: totalGrossWeight.toFixed(3),
            totalDiamondsWeight: totalDiamondsWeight.toFixed(2),
            totalNetWeight: totalNetWeight.toFixed(3),
            totalQuantity: totalQuantity,
            customerName: isAdmin ? values.customer?.name : "",
          },
          selectedTemplate: setting?.pdfTemplate,
          title: "Diva",
          appModule: "vendor-portal",
          model: "quotations",
        },
        values.quotationNo + "__" + values.vendorCode
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleBulkStatusUpdate = async () => {
    const modifiedProducts = values.products || [];
    modifiedProducts.map((_, productIndex) => {
      if (selectedProducts[productIndex]) {
        modifiedProducts[productIndex].orderStatus = bulkUpdateStatus;
        modifiedProducts[productIndex].dueDate =
          selectedDueDate || modifiedProducts[productIndex].dueDate;
        modifiedProducts[productIndex].dateModified = new Date();
      }
    });

    setValue("products", modifiedProducts);
    setSelectedProducts({});
    setSelectAll(false);
    formMethods.handleSubmit(onSubmit)();
  };

  const HeaderLastContainer = () => {
    const { isDirty } = useFormState();

    const handleCancel = () => {
      if (isDirty) {
        if (
          window.confirm("Are you sure to cancel? Changes will be discarded.")
        ) {
          formMethods.reset();
          setIsEditable(false);
        }
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    };

    if (isEditable) {
      return (
        <>
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "90%" }}>
              <TextField
                // label={"Remarks"}
                defaultValue={values.remarks}
                fullWidth={true}
                rows={3}
                multiline={true}
                variant={"outlined"}
                onBlur={(e) => setValue("remarks", e.target.value)}
                // style={{ width: "188px" }}
              />
            </div>
            <div style={{ width: "10%" }}>
              <div style={{ padding: "10px" }}>
                <SaveIcon
                  style={{ fontSize: "24px", color: "green" }}
                  type="submit"
                  onClick={formMethods.handleSubmit(onSubmit)}
                />
              </div>
              <div style={{ padding: "10px" }}>
                <CancelIcon
                  style={{ fontSize: "24px", color: "red" }}
                  onClick={handleCancel}
                />
              </div>
            </div>
          </div>
        </>
      );
    } else if (!isVendor && !isAnySelected) {
      return (
        <div
          style={{
            border: "1px solid black",
            height: "100%",
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: "#F5F3EF",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <BorderColorIcon
              style={{ fontSize: "16px" }}
              onClick={() => setIsEditable(true)}
            />
          </div>
          <div style={{ lineHeight: "16px", fontSize: "12px" }}>
            <p>{values?.remarks}</p>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const DetailsHeader = () => {
    const { watch } = useFormContext();
    const quotation = watch();
    const showBulkUpdateDropdown = !isEditable && isAnySelected;

    const modifiedDates = (filteredProducts || [])
      .filter((prod) => prod.dateModified)
      .map((prod) => new Date(prod.dateModified).getTime());

    let statusDate = modifiedDates?.length ? Math.min(...modifiedDates) : null;
    statusDate = statusDate ? formatDateToRead(statusDate) : "";

    const dateModified = statusDate;

    const dueDates = (filteredProducts || [])
      .filter((prod) => !!prod?.dueDate)
      .map((prod) => new Date(prod.dueDate).getTime());

    const dueDate = dueDates.length ? new Date(Math.min(...dueDates)) : null;

    return (
      <div
        style={{
          marginBottom: showBulkUpdateDropdown ? "24px" : "0px",
        }}
      >
        <Grid container spacing={2} style={{ overFlow: "hidden" }}>
          <Grid xs={3}>
            <div
              style={{
                backgroundColor: "white",
                padding: "20px 20px 0px 20px",
                height: "160px",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                className="header-section-1"
              >
                {!isEditable &&
                  (isVendor
                    ? !filteredProducts?.find(
                        (prod) =>
                          prod?.orderStatus ===
                            enums["vendor-portal"]?.orderStatus?.assigned ||
                          prod?.orderStatus ===
                            enums["vendor-portal"]?.orderStatus?.pending ||
                          prod?.orderStatus ===
                            enums["vendor-portal"]?.orderStatus?.canceled
                      )
                    : true) &&
                  (loading ? (
                    <pre style={{ fontSize: "12px" }}>Downloading...</pre>
                  ) : (
                    <Button
                      variant="contained"
                      // component="label"
                      onClick={downloadPdf}
                      style={{ backgroundColor: "#7CD093", color: "white" }}
                    >
                      <FileDownloadIcon style={{ color: "white" }} />
                    </Button>
                  ))}

                {!isEditable && (
                  <Button
                    variant="contained"
                    component="label"
                    style={{ backgroundColor: "#7CD093", color: "white" }}
                    onClick={handleBulkStatusUpdate}
                    disabled={!isAnySelected}
                  >
                    Bulk Status Update
                  </Button>
                )}
              </Stack>

              <div className="header-section-1" style={{ marginTop: "24px" }}>
                {showBulkUpdateDropdown && (
                  <Autocomplete
                    disableClearable
                    options={
                      Object.values(enums["vendor-portal"]?.orderStatus).filter(
                        (status) =>
                          isVendor
                            ? !(
                                status ===
                                  enums["vendor-portal"]?.orderStatus
                                    ?.assigned ||
                                status ===
                                  enums["vendor-portal"]?.orderStatus
                                    ?.pending ||
                                status ===
                                  enums["vendor-portal"]?.orderStatus?.resent
                              )
                            : true
                      ) || []
                    }
                    disabled={
                      !(isEditable || isAnySelected) &&
                      !filteredProducts.find(
                        (prod, prodIndex) =>
                          prod.orderStatus !==
                            enums["vendor-portal"]?.orderStatus?.pending &&
                          selectedProducts[prodIndex]
                      )
                    }
                    size="small"
                    onChange={(_, value) => {
                      setBulkUpdateStatus(value);
                    }}
                    style={{ width: "270px" }}
                    value={bulkUpdateStatus}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        size="small"
                        style={{ width: "100%" }}
                        variant={"outlined"}
                        label={"Order Status"}
                      />
                    )}
                  />
                )}

                {!isEditable && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <MyKeyboardDatePicker
                        label="Due Date"
                        value={selectedDueDate}
                        InputProps={{ readOnly: true }}
                        disablePast={true}
                        // style={{ width: "270px" }}
                        onChange={(value) => {
                          setSelectedDueDate(value);
                        }}
                        disabled={isVendor || !(isEditable || isAnySelected)}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Label
                        label={"Status Updated on"}
                        value={dateModified && formatDateToRead(dateModified)}
                      />
                    </Grid>
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
          <Grid xs>
            <div
              style={{
                backgroundColor: "white",
                height: "160px",
                padding: "20px",
              }}
            >
              <div style={{ margin: "0px", lineHeight: "0px" }}>
                <p style={{}}>Order Date</p>
                <p
                  style={{
                    fontWeight: "bolder",
                    fontSize: "16px",
                    paddingTop: "10px",
                    color: "black",
                  }}
                >
                  {quotation?.orderDate &&
                    formatDateToRead(quotation.orderDate)}
                </p>
              </div>
              <div
                style={{ margin: "0px", lineHeight: "0px", paddingTop: "10px" }}
              >
                <p>Due Date</p>
                <p
                  style={{
                    fontWeight: "bolder",
                    fontSize: "18px",
                    paddingTop: "10px",
                    color: "black",
                  }}
                >
                  {(dueDate && formatDateToRead(dueDate)) || ""}
                </p>
              </div>
            </div>
          </Grid>
          <Grid xs>
            <div
              style={{
                backgroundColor: "white",
                height: "160px",
                padding: "20px",
              }}
            >
              <div style={{ margin: "0px", lineHeight: "0px" }}>
                <p style={{}}>Quotation Number</p>
                <p
                  style={{
                    fontWeight: "bolder",
                    fontSize: "16px",
                    paddingTop: "10px",
                    color: "black",
                  }}
                >
                  {quotation?.quotationNo}
                </p>
              </div>
              <div
                style={{ margin: "0px", lineHeight: "0px", paddingTop: "10px" }}
              >
                <p>Screw</p>
                <p
                  style={{
                    fontWeight: "bolder",
                    fontSize: "18px",
                    paddingTop: "10px",
                    color: "black",
                  }}
                >
                  {filteredProducts?.find(
                    (prod) => displayMeltingAsPerClient(prod?.metalPurity) == 92
                  )
                    ? "South Screw"
                    : filteredProducts?.find(
                        (prod) =>
                          displayMeltingAsPerClient(prod?.metalPurity) == 76
                      ) && "Bombay Screw"}
                </p>
              </div>
            </div>
          </Grid>
          <Grid xs={3}>
            <div
              style={{
                backgroundColor: "white",
                height: "160px",
                padding: "20px",
              }}
            >
              <div style={{ margin: "0px", lineHeight: "0px" }}>
                <p style={{}}>Vendor</p>
                {isEditable &&
                !isVendor &&
                isVendorCodeEditable({}, initialValues) ? (
                  <RHAutoComplete
                    disableClearable
                    options={vendorCodes}
                    size="small"
                    onChange={(_, value) => {
                      const tempValues = getValues();

                      const tempProducts = (tempValues.products || []).map(
                        (prod) => {
                          if (
                            !(
                              prod.orderStatus !==
                                enums["vendor-portal"]?.orderStatus.pending ||
                              prod.adminRemarks
                            )
                          ) {
                            return { ...prod, vendorCode: value };
                          }

                          return prod;
                        }
                      );

                      setValue("products", tempProducts);
                    }}
                    name={`vendorCode`}
                  />
                ) : (
                  <p
                    style={{
                      fontWeight: "bolder",
                      fontSize: "16px",
                      paddingTop: "10px",
                      color: "black",
                    }}
                  >
                    {values.vendorCode}
                  </p>
                )}
              </div>
              <div
                style={{
                  margin: "0px",
                  lineHeight: "0px",
                  paddingTop: "10px",
                }}
              >
                <p>Customer Name</p>
                <p
                  style={{
                    fontWeight: "bolder",
                    fontSize: "18px",
                    // paddingTop: "10px",
                    color: "black",
                    lineHeight: "20px",
                  }}
                >
                  {!isVendor && quotation?.customer?.name}
                </p>
              </div>
            </div>
          </Grid>
          <Grid xs={3}>
            <div
              style={{
                backgroundColor: "white",
                height: "160px",
                padding: "20px",
              }}
            >
              <div style={{ margin: "0px", lineHeight: "0px" }}>
                <p style={{}}>Overall Remarks</p>
                <div>
                  <HeaderLastContainer />

                  {/* <TextField
                    id="outlined-multiline-static"
                    label="Multiline1"
                    multiline
                    rows={4}
                    defaultValue="Default Value"
                  /> */}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <ElevatedPaper>
      <FormProvider {...formMethods}>
        {/* <CompHeader /> */}
        <DetailsHeader />
        <table className="tables">
          <tr className="tables-header" style={{}}>
            <th className="tables-header-content">
              <div
                style={{
                  position: "relative",
                  // top: -30,
                  left: 24,

                  // zIndex: 100,
                }}
              >
                {!isEditable && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            const obj = {};

                            filteredProducts.map((prod, prodIndex) => {
                              if (prod?.styleNo)
                                obj[prodIndex] = event.target.checked;
                            });

                            setSelectedProducts(obj);
                            setSelectAll(event.target.checked);
                          }}
                          checked={!!selectAll}
                          sx={{
                            color: commonConfig.themeColors.green,
                            "&.Mui-checked": {
                              color: commonConfig.themeColors.green,
                            },
                          }}
                        />
                      }
                      // label={`Select All`}
                    />
                  </FormGroup>
                )}
              </div>
            </th>
            <th className="tables-header-content">Cust. OD#</th>
            <th className="tables-header-content">Design Number</th>
            <th className="tables-header-content">Qty</th>
            <th className="tables-header-content">
              <div>Gross</div>
              <div>Net</div>
            </th>
            <th className="tables-header-content">
              <div>Color</div>
              <div>Melting</div>
            </th>
            <th className="tables-header-content">Size</th>
            <th className="tables-header-content">Exhibition Remarks</th>
            <th className="tables-header-content">Admin Remarks</th>
            <th className="tables-header-content">Vendor Code</th>
            <th className="tables-header-content">Status</th>
          </tr>
          {fields.map((product, productIndex) => {
            return (
              getProductViewAccess(product) && (
                <ProductsView
                  key={product.id}
                  product={product}
                  productIndex={productIndex}
                  isEditable={isEditable}
                  setSelectedProducts={setSelectedProducts}
                  selectedProducts={selectedProducts}
                  vendorCodes={vendorCodes}
                  initialValues={initialValues}
                />
              )
            );
          })}
        </table>
      </FormProvider>
    </ElevatedPaper>
  );
};

export default QuotationDetail;
