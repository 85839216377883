import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "@ui/components/AuthProvider";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useEffect } from "react";
import { Tooltip } from "@material-ui/core";
import {
  defaultGoldHex,
  isVendor,
  selectedDivisionId,
  userDetails,
} from "@ui/Utils/helper";
import { dynamicApiCall, search } from "@ui/ComponentUtils/blueprintAPIs";
import { Autocomplete } from "@material-ui/lab/index";
import { TextField } from "@material-ui/core/index";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { filterDivisionsWithUserAccess, logout } from "@ui/Auth/api";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import commonConfig from "config/commonConfig";
import { NAVIGATE_APP_TO } from "@ui/components/LandingPage/LandingPage";

// import "./responsiveDrawer.css"; // shining star is disturbing if it always shines..

const constDrawerWidth = 200;

const handleDivisionChange = (division, setLocalDivision) => {
  if (division?._id && division._id !== selectedDivisionId) {
    window.sessionStorage.clear();

    sessionStorage.setItem("division", JSON.stringify(division));
    CustomEventEmitter.emit("alert_success", "Switching...");
    setLocalDivision && setLocalDivision(division);
    window.location.reload();
  }
};

const ResponsiveDrawer = (props) => {
  const {
    children,
    appName,
    secondaryMenus = [],
    primaryMenus = [],
    activeMenuIndex = 0,
    userInfo = {},
  } = props;
  const auth = useAuth();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(true);
  const [activeMenu, setActiveMenu] = React.useState({});
  const [onMenuHover, setOnMenuHover] = React.useState(false);
  const [onIconHover, setOnIconHover] = React.useState({});
  const [divisions, setDivisions] = React.useState([]);
  const [localDivision, setLocalDivision] = React.useState({});
  const location = useLocation();
  const drawerWidth = mobileOpen ? constDrawerWidth : 50;
  const isDivisionControlled =
    userInfo?.client?.premiumClient?.divisionControl ||
    userDetails?.client?.premiumClient?.divisionControl;
  const urlPaths = location.pathname?.toString().split("/");

  useEffect(() => {
    if (isDivisionControlled) {
      (async () => {
        try {
          const res =
            (await dynamicApiCall(
              `${commonConfig.baseApiUrl}data-engine/divisions/search`,
              "post",
              { filterColumns: { _id: 1, name: 1, image: 1, description: 1 } }
            )) || [];

          setDivisions(filterDivisionsWithUserAccess(res));
        } catch (err) {
          console.log(err);
        }
      })();
      const tmpLocalDiv =
        sessionStorage.getItem("division") &&
        JSON.parse(sessionStorage.getItem("division"));

      if (tmpLocalDiv?._id) {
        setLocalDivision(tmpLocalDiv);
      }
    }
  }, []);

  useEffect(() => {
    if (
      activeMenu?.hideMenuByDefault &&
      location.pathname
        ?.toString()
        .toLowerCase()
        .match(activeMenu.path?.toLowerCase())
    ) {
      setMobileOpen(false);
    }
  }, [activeMenu, location]);

  useEffect(() => {
    primaryMenus[activeMenuIndex] &&
      setActiveMenu(primaryMenus[activeMenuIndex]);
  }, [activeMenuIndex]);

  useEffect(() => {
    const tempActiveMenu = primaryMenus?.find((menu) =>
      location?.pathname
        ?.toString()
        .toLowerCase()
        .match(menu?.path?.toLowerCase())
    );
    if (tempActiveMenu) {
      setActiveMenu(tempActiveMenu);
    }
  }, [location?.pathname]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!auth.user) return <>{children}</>;

  const AppName = () => {
    const handleMenuHover = () => {
      setOnMenuHover(!onMenuHover);
    };

    return (
      <div
        style={{
          backgroundColor: onMenuHover ? defaultGoldHex : "#ffffff",
          display: "flex",
          alignItems: "center",
          padding: "10px",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
          transition: "background-color 0.3s ease", // Smooth background color transition
          transform: onMenuHover
            ? "scale(1.1)" // Scale up when hovered
            : "scale(1)", // Scale back to normal
        }}
        onMouseEnter={handleMenuHover}
        onMouseLeave={handleMenuHover}
      >
        <MenuIcon
          style={{
            marginRight: mobileOpen && "24px",
            color: onMenuHover ? "#ffffff" : defaultGoldHex,
            cursor: "pointer",
            transition: "color 0.3s ease", // Smooth color transition
          }}
        />
        <span
          style={{
            color: onMenuHover ? "#ffffff" : defaultGoldHex,
            marginLeft: "10px",
            fontWeight: "bold",
            textTransform: "uppercase",
            fontSize: "14px",
            transition: "color 0.3s ease", // Smooth color transition
          }}
        >
          {mobileOpen && (appName || "App Name")}
        </span>
      </div>
    );
  };

  const drawer = (
    <div>
      <div
        onClick={handleDrawerToggle}
        style={{
          // paddingTop: "8px",
          cursor: "pointer",
          position: "sticky", // Make the entire div sticky
          top: "0", // Stick to the top of the viewport
          zIndex: 1000, // Ensure it stays above other content
        }}
        onMouseEnter={() => setOnMenuHover(true)}
        onMouseLeave={() => setOnMenuHover(false)}
      >
        {mobileOpen ? (
          <Typography variant="h6" noWrap component="div">
            <Tooltip title="Toggle Menu">
              <AppName />
            </Tooltip>
          </Typography>
        ) : (
          <Tooltip title="Toggle Menu">
            <MenuOpenIcon
              style={{
                color: onMenuHover ? "black" : "grey",
                fontSize: 28,
                marginTop: "20px",
                transition: "color 0.3s ease", // Smooth color transition
                marginLeft: "12px",
                backgroundColor: "white",
              }}
            />
          </Tooltip>
        )}
      </div>
      <Divider />
      <div>
        {mobileOpen ? (
          <div>
            <List style={{ overflowX: "hidden" }}>
              {primaryMenus.map((menu, index) =>
                menu.parent ? (
                  <h4 key={index} style={menu.titleStyle}>
                    {menu.title}
                  </h4>
                ) : (
                  menu.access && (
                    <ListItem
                      style={{
                        backgroundColor:
                          menu?.title === activeMenu?.title
                            ? "lightgrey"
                            : "white",
                        height: menu?.title === activeMenu?.title ? 48 : 38,
                        // padding: "8px",
                        cursor: "pointer",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        transition: "background-color 0.3s ease", // Smooth background color transition
                      }}
                      key={index}
                      disablePadding
                      onClick={() => {
                        if (mobileOpen && menu?.title === activeMenu?.title) {
                          setMobileOpen(false);
                        }
                        if (menu?.title !== activeMenu?.title) {
                          setActiveMenu(menu);
                        }
                        navigate(menu.path);
                      }}
                    >
                      <ListItemButton
                        onMouseEnter={() => setOnIconHover(menu)}
                        onMouseLeave={() => setOnIconHover({})}
                        style={{
                          transition: "transform 0.3s ease", // Smooth transition for scale
                          transform:
                            activeMenu?.title === menu.title ||
                            onIconHover?.title === menu.title
                              ? "scale(1.2)" // Scale up when hovered
                              : "scale(1)", // Scale back to normal
                        }}
                      >
                        <ListItemIcon
                          style={
                            activeMenu?.title === menu.title ||
                            onIconHover?.title === menu.title
                              ? { color: "black", fontSize: 30 }
                              : {}
                          }
                        >
                          {activeMenu?.title === menu.title ||
                          onIconHover?.title === menu.title
                            ? menu.selectedIcon
                              ? menu.selectedIcon()
                              : menu.icon()
                            : menu.icon && menu.icon()}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <div style={menu.titleStyle}>{menu.title}</div>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  )
                )
              )}
            </List>
            <Divider />
            <List
              style={{
                padding: "20px",
              }}
            >
              {secondaryMenus.map(
                (menu, index) =>
                  menu.access && (
                    <ListItem
                      key={index}
                      disablePadding
                      onClick={() => navigate(menu.path)}
                    >
                      <ListItemButton>
                        <ListItemIcon>{menu.icon()}</ListItemIcon>
                        <ListItemText primary={menu.title} />
                      </ListItemButton>
                    </ListItem>
                  )
              )}

              {isDivisionControlled && divisions?.length ? (
                <div style={{ padding: "24px 24px 24px 0" }}>
                  <Autocomplete
                    options={divisions || []}
                    size="small"
                    value={localDivision}
                    getOptionLabel={(option) => option?.name || ""}
                    getOptionSelected={(option, value) =>
                      option?.name === value?.name
                    }
                    disableClearable
                    onChange={(_, value) => {
                      handleDivisionChange(value);
                    }}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        size="small"
                        style={{ width: "100%" }}
                        variant={"outlined"}
                        label={"Division"}
                      />
                    )}
                  />
                </div>
              ) : null}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  marginBottom: "68px",
                }}
              >
                <div style={{ marginRight: "24px" }}>
                  <Tooltip title="Go Home">
                    <AccountBalanceIcon
                      onMouseEnter={(event) => {
                        event.target.style.transition = "transform 0.3s ease"; // Smooth transition for scale
                        event.target.style.transform = "scale(1.5)";
                      }}
                      onMouseLeave={(event) => {
                        event.target.style.transition = "transform 0.3s ease"; // Smooth transition for scale
                        event.target.style.transform = "scale(1)";
                      }}
                      style={{
                        fontSize: "48px",
                        cursor: "pointer",
                        color: "black",
                        transition: "color 0.3s ease", // Smooth color transition
                      }}
                      onClick={() => {
                        window.localStorage.removeItem("division");
                        window.localStorage.removeItem(NAVIGATE_APP_TO);
                        navigate("/home");
                      }}
                    />
                  </Tooltip>
                </div>

                {userDetails?.client?.premiumClient?.unlimitedBucketStorage ||
                userDetails?.client?.premiumClient?.divisionControl ? (
                  <div style={{ marginTop: "8px" }}>
                    <Tooltip
                      title={
                        "Pro" +
                        (() => {
                          const trueFieldsCount = Object.keys(
                            userDetails?.client?.premiumClient || {}
                          ).filter(
                            (key) =>
                              userDetails?.client?.premiumClient[key] === true
                          ).length;

                          // Map numbers to their respective power symbols
                          const powerMap = {
                            2: "²",
                            3: "³",
                            4: "⁴",
                            5: "⁵",
                            6: "⁶",
                            7: "⁷",
                            8: "⁸",
                            9: "⁹",
                            10: "¹⁰", // Custom handling for 10 and beyond
                          };

                          return trueFieldsCount > 1
                            ? powerMap[trueFieldsCount] || `¹${trueFieldsCount}`
                            : "";
                        })()
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          fontSize: "14px",
                          fontWeight: "bold",
                          cursor: "grab",
                          userSelect: "none",
                        }}
                        onMouseEnter={(event) => {
                          event.target.style.transition = "transform 0.3s ease";
                          event.target.style.transform = "scale(1.3)";
                        }}
                        onMouseLeave={(event) => {
                          event.target.style.transition = "transform 0.3s ease";
                          event.target.style.transform = "scale(1)";
                        }}
                        onMouseDown={(event) => {
                          event.target.style.cursor = "grabbing";
                        }}
                        onMouseUp={(event) => {
                          event.target.style.cursor = "grab";
                        }}
                      >
                        {/* Display power stars based on number of true fields */}
                        <div style={{ letterSpacing: "8px" }}>
                          {"⭐".repeat(
                            Math.min(
                              Object.keys(
                                userDetails?.client?.premiumClient || {}
                              ).filter(
                                (key) =>
                                  userDetails?.client?.premiumClient[key] ===
                                  true
                              ).length,
                              3
                            )
                          )}
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                ) : null}
              </div>

              <div style={{ marginTop: "-44px" }}>
                <h4>
                  <pre
                    style={{
                      wordBreak: "break-word",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {auth.user?.firstName + " " + auth.user?.lastName}
                  </pre>
                </h4>

                {isVendor && (
                  <pre
                    style={{
                      fontSize: 12,
                      marginTop: "-10px",
                      color: "grey",
                      fontWeight: 300,
                      letterSpacing: "1px",
                      wordBreak: "break-all",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {auth.user?.vendorCode?.map((code) => `#${code}  `)}
                  </pre>
                )}

                <pre
                  style={{
                    fontSize: 12,
                    marginTop: "-10px",
                    color: "grey",
                    fontWeight: "bold",
                    wordBreak: "break-all",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {auth.user?.client?.name}
                </pre>
                <pre
                  style={{ fontSize: 12, marginTop: "-10px", color: "grey" }}
                >
                  {auth.user?.role}
                </pre>
                <pre
                  style={{
                    fontSize: 12,
                    marginTop: "-10px",
                    color: "grey",
                    fontStyle: "italic",
                  }}
                >
                  {auth.user?.phone}
                </pre>

                {/*
              Areyy yaar... bucket security aagya 

              <img
                src={
                  localDivision?.image?.signedUrl ||
                  userDetails?.client?.logo?.signedUrl
                }
                onMouseOver={(event) => {
                  event.target.src = userDetails?.client?.logo?.location;
                }}
                onMouseLeave={(event) => {
                  if (localDivision?.image?.location)
                    event.target.src = localDivision?.image?.location;
                }}
                onMouseDown={(event) => {
                  event.target.style.cursor = "grabbing";
                }}
                onMouseUp={(event) => {
                  event.target.style.cursor = "grab";
                }}
                style={{
                  height: "120px",
                  width: "120px",
                  padding: "20px",
                  paddingLeft: 0,
                  paddingRight: 0,
                  margin: "auto",
                  cursor: "grab",
                  userSelect: "none",
                }}
              /> */}

                <ListItem
                  disablePadding
                  onClick={async () => {
                    await logout();
                    auth.signout();
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Signout"} />
                  </ListItemButton>
                </ListItem>
              </div>
            </List>
          </div>
        ) : (
          <
            // style={{
            //   marginLeft:
            //     // mobileOpen ? "-12px" :
            //     "-28px",
            // }}
          >
            <List style={{ overflowX: "hidden" }}>
              {primaryMenus.map((menu, index) =>
                menu.parent ? (
                  <h4 key={index}>{menu.title}</h4>
                ) : (
                  menu.access && (
                    <ListItem
                      style={{
                        backgroundColor:
                          menu?.title === activeMenu?.title
                            ? "lightgrey"
                            : "white",
                        height: 48,
                        // padding: "12px 0 12px 24px",
                        // paddingLeft: "4px",
                        // paddingRight: "4px",
                        cursor: "pointer",
                        transition: "background-color 0.3s ease", // Smooth background color transition
                      }}
                      key={index}
                      disablePadding
                      onClick={() => {
                        if (mobileOpen && menu?.title === activeMenu?.title) {
                          setMobileOpen(false);
                        } else {
                          setMobileOpen(!mobileOpen);

                          if (menu?.title !== activeMenu?.title) {
                            setActiveMenu(menu);
                          }
                        }
                        navigate(menu.path);
                      }}
                    >
                      <ListItemButton
                        onMouseEnter={() => setOnIconHover(menu)}
                        onMouseLeave={() => setOnIconHover({})}
                        style={{
                          transition: "transform 0.3s ease", // Smooth transition for scale
                          transform:
                            activeMenu?.title === menu.title ||
                            onIconHover?.title === menu.title
                              ? "scale(1.1)" // Scale up when hovered
                              : "scale(1)", // Scale back to normal
                        }}
                      >
                        <ListItemIcon
                          style={
                            activeMenu?.title === menu.title ||
                            onIconHover?.title === menu.title
                              ? { color: "black", fontSize: 30 }
                              : {}
                          }
                        >
                          <Tooltip title={menu.title}>
                            {activeMenu?.title === menu.title ||
                            onIconHover?.title === menu.title
                              ? menu.selectedIcon
                                ? menu.selectedIcon()
                                : menu.icon()
                              : menu.icon && menu.icon()}
                          </Tooltip>
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                  )
                )
              )}
            </List>
          </>
        )}
      </div>
    </div>
  );

  if (
    urlPaths.length < 3 ||
    urlPaths.find((path) => path?.toLowerCase() === "home")
  ) {
    return (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: "100%",
          transition: "width 0.3s ease", // Smooth width transition
        }}
      >
        {children}
      </Box>
    );
  } else {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
            transition: "width 0.3s ease", // Smooth width transition
          }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="permanent"
            sx={{
              display: { sm: "block" },
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                backgroundColor: "white",
                color: "black",
              },
            }}
            open={mobileOpen}
            onMouseEnter={() => {
              setMobileOpen(true);
            }}
            onMouseLeave={() => {
              setMobileOpen(false);
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            height: "100%",
          }}
        >
          {children}
        </Box>
      </Box>
    );
  }
};

export default ResponsiveDrawer;
